var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"},on:{"click":function($event){(_vm.contextmenuButtonShow = false), (_vm.menuShow = false)}}},[_c('a-dropdown',{attrs:{"overlayClassName":"sqlDropdown","trigger":['contextmenu']},model:{value:(_vm.contextmenuButtonShow),callback:function ($$v) {_vm.contextmenuButtonShow=$$v},expression:"contextmenuButtonShow"}},[_c('ux-grid',{ref:"plxTable",staticClass:"editable_table_style",attrs:{"stripe":"","show-overflow":"title","size":"mini"},on:{"table-body-scroll":_vm.scroll,"row-click":_vm.rowClick,"row-contextmenu":_vm.rowContextmenu,"cell-dblclick":_vm.cellDblclick,"sort-change":_vm.sortHandleChange},nativeOn:{"contextmenu":function($event){(_vm.menuShow = true), (_vm.rowContextShow = false)}}},_vm._l((_vm.columnsList),function(item,key){return _c('ux-table-column',{key:item.columnName,attrs:{"field":item.columnName,"resizable":"","title":item.columnName,"sortable":item.sortable,"remote-sort":item.sorter,"width":item.width,"min-width":item.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"ellipsis_style",class:[
              scope.row[item.columnName]
                ? scope.row[item.columnName] === 'NULL'
                  ? 'td_empty'
                  : scope.row[item.columnName] === 'DEFAULT'
                  ? 'td_empty'
                  : ''
                : 'td_empty',
              item.columnName === scope.row.edit_text[item.columnName]
                ? 'td_yellow'
                : '',
              item.columnName === scope.row.col ? 'td_margin' : 'td_padding',
              scope.row.delete_row ? 'td_red' : '',
              scope.row.add_row ? 'td_green' : '',
            ],attrs:{"title":scope.row[item.columnName]}},[(
                item.columnName === scope.row.col &&
                !item.is_autoComplete_show
              )?_c('a-input',{staticStyle:{"padding":"0 5px"},attrs:{"value":scope.row[item.columnName]
                  ? scope.row[item.columnName] === 'NULL'
                    ? ''
                    : scope.row[item.columnName] === 'DEFAULT'
                    ? ''
                    : scope.row[item.columnName]
                  : '',"placeholder":scope.row[item.columnName] === 'NULL'
                  ? 'NULL'
                  : scope.row[item.columnName] === 'DEFAULT'
                  ? 'DEFAULT'
                  : 'EMPTY'},on:{"change":(e) =>
                  _vm.inputHandleChange(
                    e.target.value,
                    scope.row[_vm.rowKey],
                    item.columnName
                  ),"blur":function($event){return _vm.blueSQL(
                  scope.row,
                  item.columnName,
                  scope.rowIndex,
                  scope.row[item.columnName]
                )},"pressEnter":function($event){$event.stopPropagation();return _vm.blueSQL(
                  scope.row,
                  item.columnName,
                  scope.rowIndex,
                  scope.row[item.columnName]
                )}}}):(
                item.columnName === scope.row.col && item.is_autoComplete_show
              )?_c('a-auto-complete',{staticStyle:{"width":"200px"},attrs:{"dataSource":_vm.autocompleteList,"value":scope.row[item.columnName]
                  ? scope.row[item.columnName] === 'NULL'
                    ? ''
                    : scope.row[item.columnName] === 'DEFAULT'
                    ? ''
                    : scope.row[item.columnName]
                  : '',"placeholder":scope.row[item.columnName] === 'NULL'
                  ? 'NULL'
                  : scope.row[item.columnName] === 'DEFAULT'
                  ? 'DEFAULT'
                  : 'EMPTY',"filterOption":_vm.filterOption},on:{"change":(e) =>
                  _vm.inputHandleChange(e, scope.row[_vm.rowKey], item.columnName),"blur":function($event){return _vm.blueSQL(
                  scope.row,
                  item.columnName,
                  scope.rowIndex,
                  scope.row[item.columnName]
                )}}}):[(
                  item.columnName !== scope.row.col &&
                  scope.row[item.columnName] !== ''
                )?[_vm._v(" "+_vm._s(scope.row[item.columnName])+" ")]:[_vm._v(" EMPTY ")],(_vm.selectContent && item.is_select_show)?_c('div',{staticClass:"up_down_style",on:{"click":function($event){$event.stopPropagation();return _vm.selectClick($event, item, scope.row, scope.rowIndex)}}},[_c('a-icon',{attrs:{"type":"up"}}),_c('a-icon',{attrs:{"type":"down"}})],1):_vm._e()]],2)]}}],null,true)})}),1),(_vm.menuShow)?_c('a-menu',{staticStyle:{"width":"100px"},attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{on:{"click":() => {
            _vm.$emit('addRow');
            _vm.menuShow = false;
          }}},[_c('a-button',{attrs:{"type":"link","size":"small","icon":"plus"}},[_vm._v(" 新增 ")])],1),(_vm.rowContextShow)?_c('a-menu-item',{on:{"click":() => {
            _vm.$emit('copyRow');
            _vm.menuShow = false;
          }}},[_c('a-button',{attrs:{"type":"link","size":"small","icon":"copy"}},[_vm._v(" 复制 ")])],1):_vm._e(),(_vm.rowContextShow)?_c('a-menu-item',{on:{"click":() => {
            _vm.$emit('deleteRow');
            _vm.menuShow = false;
          }}},[_c('a-button',{attrs:{"type":"link","size":"small","icon":"delete"}},[_vm._v(" 删除 ")])],1):_vm._e(),(_vm.type == 'content')?_c('a-menu-item',{on:{"click":() => {
            _vm.$emit('refreshContentTable');
            _vm.menuShow = false;
          }}},[_c('a-button',{attrs:{"type":"link","size":"small","icon":"sync"}},[_vm._v(" 刷新 ")])],1):_vm._e(),(_vm.type == 'query' && _vm.rowContextShow)?_c('a-menu-item',{on:{"click":() => {
            _vm.$emit('lineDetails');
            _vm.menuShow = false;
          }}},[_c('a-button',{attrs:{"type":"link","size":"small","icon":"file-search"}},[_vm._v(" 单行详情 ")])],1):_vm._e(),(_vm.type == 'query')?_c('a-menu-item',{on:{"click":() => {
            _vm.$emit('exportExcel');
            _vm.menuShow = false;
          }}},[_c('a-button',{attrs:{"type":"link","size":"small","icon":"file-search"}},[_vm._v(" 导出 ")])],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }