<template>
  <div class="sqlCommandSystem" @click="closeDiv">
    <!-- 过度动画 -->
    <div class="load_animation" v-if="loadingShow">
      <div class="load_content">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="时长">
            <b>
              {{ $common.timeConsuming(loadingSqlTime) }}
            </b>
            <a-spin size="small" style="margin-left: 5px" />
          </a-form-item>
          <div class="loading_style cancelButton" v-if="loadingSqlTime >= 3">
            <a-button type="primary" @click="cancelLoading">取消执行</a-button>
          </div>
        </a-form>
      </div>
    </div>
    <div class="load_animation" v-if="loadingAsyncExecutionShow">
      <div class="load_content">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="sql">
            <b>
              {{ loadingSql }}
            </b>
          </a-form-item>
          <a-form-item label="时长">
            <b>
              {{ $common.timeConsuming(loadingSqlTime) }}
            </b>
            <a-spin size="small" style="margin-left: 5px" />
          </a-form-item>
          <div class="loading_style cancelButton" v-if="asyncExecutionShow">
            <a-button type="primary" @click="asyncExecution">异步执行</a-button>
            <a-button type="primary" @click="cancelExecution"
              >取消执行</a-button
            >
          </div>
        </a-form>
      </div>
    </div>
    <!-- 自定义弹框 -->
    <div id="div1">
      <ul>
        <li
          @mouseenter="closeSubMenu"
          :disabled="
            tableCtrlClickList.length > 0 ||
            tableShiftClickList.length > 0 ||
            nodeLabel == ''
          "
          @click="showTableContent()"
        >
          <a-icon type="edit" />
          打开表
        </li>
        <li
          @mouseenter="closeSubMenu"
          style="border-bottom: 1px solid #e1e1e1"
          @click="editTableStructure"
          :disabled="
            tableCtrlClickList.length > 0 ||
            tableShiftClickList.length > 0 ||
            nodeLabel == ''
          "
        >
          <a-icon type="edit" />
          编辑表结构
        </li>
        <li @mouseenter="closeSubMenu" @click.stop="createTable">
          <a-icon type="plus" />
          创建表
        </li>
        <li
          @mouseenter="closeSubMenu"
          @click.stop="dropTable"
          :disabled="nodeLabel == ''"
        >
          <a-icon type="delete" />
          删除表
        </li>
        <li
          @mouseenter="closeSubMenu"
          @click.stop="copyTable"
          :disabled="
            tableCtrlClickList.length > 0 ||
            tableShiftClickList.length > 0 ||
            nodeLabel == ''
          "
        >
          <a-icon type="copy" />
          复制表
        </li>
        <li
          @mouseenter="closeSubMenu"
          @click.stop="truncateTable"
          :disabled="nodeLabel == ''"
        >
          <a-icon type="close-circle" />
          清空表
        </li>
        <li
          @mouseenter="closeSubMenu"
          @click="renameTable"
          :disabled="
            tableCtrlClickList.length > 0 ||
            tableShiftClickList.length > 0 ||
            nodeLabel == ''
          "
          style="border-bottom: 1px solid #e1e1e1"
        >
          <a-icon type="edit" />
          重命名表
        </li>
        <li
          @mouseenter="subMenuShow1_4"
          :disabled="
            tableCtrlClickList.length > 0 ||
            tableShiftClickList.length > 0 ||
            nodeLabel == ''
          "
        >
          <a-icon type="export" />
          导出
          <i
            class="el-icon-caret-right"
            style="float: right; margin-top: 2px"
          />
        </li>
        <li
          @mouseenter="closeSubMenu"
          :disabled="
            tableCtrlClickList.length > 0 || tableShiftClickList.length > 0
          "
          @click="importData"
        >
          <a-icon type="export" />
          导入
        </li>

        <li @mouseenter="closeSubMenu" @click="inquireTable(true)">
          <a-icon type="sync" />
          刷新
        </li>
      </ul>
      <div id="div1_4" style="width: 160px">
        <ul>
          <li @click="exportExcel('EXCEL')">生成Excel文档</li>
          <li @click="exportExcel('CSV')">生成CSV文档</li>
          <li @click="exportExcel('SQL')">生成SQL文档</li>
        </ul>
      </div>
    </div>
    <a-layout style="height: 100%">
      <!-- 头部栏 -->
      <a-layout-header class="header">
        <a-tooltip>
          <template slot="title"> 切换到sql管理页面 </template>
          <a-button
            class="button"
            @click="jumpAdmin"
            type="link"
            style="margin-right: 5px"
          >
            <icon-font type="icon-qiehuan" />
          </a-button>
        </a-tooltip>
        <a-tooltip>
          <template slot="title">
            {{ leftShow ? "收起" : "展开" }}侧边栏
          </template>
          <a-button
            class="button"
            @click="leftClick"
            type="link"
            style="margin-right: 5px"
          >
            <icon-font type="icon-shouqi1" v-if="leftShow" />
            <icon-font type="icon-zhankai1" v-else />
          </a-button>
        </a-tooltip>
        <a-select
          v-model="instanceId"
          placeholder="选择数据库实例"
          @change="instanceChange()"
          @focus="inquireList()"
          :dropdownMatchSelectWidth="false"
          class="title"
        >
          <a-select-option
            v-for="item in instanceList"
            :key="item.id"
            :value="item.id"
          >
            <icon-font
              v-if="item.databaseType == 'CLICKHOUSE'"
              type="icon-ClickHouse"
            />
            <icon-font v-if="item.databaseType == 'MYSQL'" type="icon-MySQL" />
            <icon-font v-if="item.databaseType == 'REDIS'" type="icon-redis" />
            <icon-font v-if="item.databaseType == 'TRINO'" type="icon-Trino" />
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-tooltip>
          <template slot="title"> 刷新实例 </template>
          <a-button
            type="link"
            @click="inquireList(false, true)"
            class="button"
            style="margin-left: 5px"
            icon="sync"
          >
          </a-button>
        </a-tooltip>

        <div
          class="headerTitle"
          style="background-color: #f9d8d5"
          v-if="executedStatementsTotal > 0"
        >
          <div>
            Executed Statements Count: {{ executedStatementsCount }}/{{
              executedStatementsTotal
            }}
          </div>
          <div style="margin-left: auto; margin-right: 8px">
            {{ $common.timeConsuming(executedStatementsTime) }}
          </div>
        </div>
        <a-popover
          placement="bottom"
          overlayClassName="tagColorPopover"
          trigger="click"
          v-else
        >
          <template slot="content">
            <div class="colorList">
              <div class="label">颜色:</div>
              <div
                class="colorDiv white"
                @click="setColorAndTag('white')"
              ></div>
              <div class="colorDiv blue" @click="setColorAndTag('blue')"></div>
              <div
                class="colorDiv yellow"
                @click="setColorAndTag('yellow')"
              ></div>
              <div
                class="colorDiv green"
                @click="setColorAndTag('green')"
              ></div>
              <div class="colorDiv red" @click="setColorAndTag('red')"></div>
            </div>
            <div class="tagList">
              <div class="label">标签:</div>

              <a-tag color="red" @click="setColorAndTag(null, '正式')"
                >正式</a-tag
              >
              <a-tag color="orange" @click="setColorAndTag(null, '测试')"
                >测试</a-tag
              >
              <a-tag color="green" @click="setColorAndTag(null, '开发')"
                >开发</a-tag
              >
            </div>
          </template>
          <div
            class="headerTitle"
            :style="{ backgroundColor: color }"
            v-if="headerTitle"
          >
            <div>
              {{ headerTitle }} {{ tableTitle ? " : " + tableTitle : "" }}
            </div>
            <a-tag
              :color="
                tag == '正式' ? 'red' : tag == '测试' ? 'orange' : 'green'
              "
              style="margin-left: auto"
              >{{ tag }}</a-tag
            >
          </div>
        </a-popover>

        <a-tooltip>
          <template slot="title"> 查看活跃进程 </template>
          <a-button
            class="button"
            @click="showProcessList"
            type="link"
            style="margin-left: auto"
          >
            <icon-font type="icon-renwujincheng" />
          </a-button>
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> 导入导出记录 </template>
          <a-badge class="badge" :count="importOrExportCount" dot>
            <a-button class="button" @click="importOrExportData" type="link">
              <icon-font type="icon-daochudaoru" />
            </a-button>
          </a-badge>
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> 异步执行任务列表 </template>
          <a-badge class="badge" :count="asyncBadgeCount" dot>
            <a-button
              class="button"
              @click="listAsyncSqlJob"
              type="link"
              style="margin-right: 5px"
            >
              <icon-font type="icon-yibuliucheng" />
            </a-button>
          </a-badge>
        </a-tooltip>
        <a-dropdown
          overlayClassName="sqlDropdown"
          style="
            display: inline-block;
            height: 100%;
            vertical-align: initial;
            text-align: center;
          "
        >
          <span style="cursor: pointer">
            <a-avatar
              class="avatar"
              size="small"
              shape="circle"
              :src="loginAvatarUrl"
              v-if="loginAvatarUrl"
            />
          </span>
          <a-menu style="width: 150px" slot="overlay">
            <a-menu-item>
              <a-button type="link" size="small">
                <router-link tag="a" target="_blank" :to="{ name: 'empower' }">
                  发布管理
                </router-link>
              </a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button type="link" size="small" @click="keyboard()">
                键盘快捷方式
              </a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button
                type="link"
                size="small"
                @click="logOut()"
                icon="poweroff"
              >
                退出登录
              </a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-layout-header>
      <a-layout class="main">
        <!-- 左侧栏 -->
        <a-layout-sider class="databaseMenu" width="70px" v-show="leftShow">
          <div
            :class="{
              databaseTab: true,
              databaseTabActive: item.databaseId == databaseId,
            }"
            v-for="(item, index) in databaseTabList"
            :key="item.databaseId"
            @click="databaseChange(item)"
          >
            <a-dropdown
              overlayClassName="sqlDropdown"
              :trigger="['contextmenu']"
            >
              <a-tooltip
                overlayClassName="dataBaseTabTooltip"
                placement="right"
              >
                <template slot="title">
                  {{ item.userNameLabel }}<br />
                  {{ item.host + ":" + item.port }}
                </template>
                <div class="icon">
                  <icon-font type="icon-database" />
                </div>
                <div class="databaseName">{{ item.databaseName }}</div>
              </a-tooltip>
              <a-menu slot="overlay" style="width: 100px">
                <a-menu-item key="1" @click="closeTab(index)">
                  <a-button type="link" size="small"> 关闭 </a-button>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div class="databaseTab" @click="addDatabase">
            <a-tooltip>
              <template slot="title"> 添加数据库 </template>
              <a-button type="link">
                <div class="icon">
                  <a-icon type="plus" />
                </div>
              </a-button>
            </a-tooltip>
          </div>
        </a-layout-sider>
        <a-layout-sider
          class="tableMenu"
          width="245px"
          style="flex: 0 0 auto; max-width: none; min-width: inherit"
          v-show="leftShow"
        >
          <!-- 模糊匹配表名 -->
          <div class="aside_input">
            <a-input-search
              v-model="searchValue"
              style="width: 98%"
              size="small"
              placeholder="模糊匹配表名"
              @change="onChangeTree"
              :disabled="tableList.length == 0 && !databaseId"
            />
          </div>
          <!-- 表名树状图 -->
          <div
            class="tableList"
            id="tableList"
            @click="nodeLabelShow = ''"
            @contextmenu.stop.prevent="onRightClick(null, $event)"
          >
            <div
              v-for="(item, index) in tableList"
              :class="{
                tableItem: true,
                checkTableItem: checkTable == item.tableName,
                drop: item.drop,
                truncate: item.truncate,
                rename: item.tableName !== item.newTableName,
                ctrlOrShiftTableItem:
                  tableShiftClickList.includes(item.tableName) ||
                  tableCtrlClickList.includes(item.tableName),
              }"
            >
              <div
                v-if="nodeLabelShow != item.title"
                class="tableName"
                @click.stop.prevent="clickTable(item, index, $event)"
                @contextmenu.stop.prevent="onRightClick(item, $event)"
              >
                <icon-font type="icon-biaoge1" class="icon" />
                <div
                  v-if="
                    item.newTableName.indexOf(searchValue) !== -1 && searchValue
                  "
                >
                  <div>
                    {{
                      item.newTableName.substr(
                        0,
                        item.newTableName.indexOf(searchValue)
                      )
                    }}
                  </div>
                  <div style="color: #ff5500">{{ searchValue }}</div>
                  <div>
                    {{
                      item.newTableName.substr(
                        item.newTableName.indexOf(searchValue) +
                          searchValue.length
                      )
                    }}
                  </div>
                </div>
                <div v-else>{{ item.newTableName }}</div>
              </div>
              <div class="tableName" v-else>
                <input
                  style="line-height: 14px"
                  v-model="nodeLabel"
                  class="treeInput"
                />
                <a-icon
                  type="check"
                  style="margin-left: 5px"
                  @click.stop="renameTableSubmit(item)"
                />
              </div>
            </div>
          </div>
        </a-layout-sider>
        <a-layout class="mainMiddle">
          <!-- 信息显示栏 -->
          <a-layout-content class="mainMiddleContent">
            <div id="tz" @mousedown="dragEagle"></div>
            <a-tabs
              :activeKey="editableTabsValue"
              @change="sqlHandleClick"
              @edit="onEdit"
              type="editable-card"
              class="tab-class"
              v-if="editWindowShow"
            >
              <a-tab-pane
                v-for="pane in sqlPanes"
                :key="pane.key"
                :tab="pane.title"
                :closable="pane.closable"
              >
                <template v-show="pane.key == editableTabsValue">
                  <!-- SQL窗口 -->
                  <template
                    v-if="
                      pane.key.substring(0, pane.key.indexOf('_')) === 'query'
                    "
                  >
                    <div :id="'textareaId_' + pane.key" class="textareaClass">
                      <textarea
                        v-if="editWindowShow"
                        ref="mycode"
                        :id="'mycode_' + pane.key"
                        class="codesql"
                        v-model="pane.content"
                      ></textarea>
                    </div>
                    <div class="cx_button">
                      <a-dropdown-button
                        overlayClassName="sqlDropdown"
                        size="small"
                        :disabled="!databaseId || implementSqlLoading"
                        @click="implementSql()"
                        style="margin-right: 10px"
                      >
                        <span style="font-size: 12px">
                          执行 ({{ keyboardList[2].keyBinding }})
                        </span>
                        <a-menu slot="overlay" :disabled="!databaseId">
                          <a-menu-item key="1">
                            <a-button
                              type="link"
                              size="small"
                              @click="formatClick"
                              v-if="databaseType !== 'REDIS'"
                              :disabled="!databaseId"
                            >
                              格式化
                            </a-button>
                          </a-menu-item>
                          <a-menu-item key="2">
                            <a-button
                              type="link"
                              size="small"
                              @click="handleMySql"
                              :disabled="!databaseId"
                            >
                              我的SQl
                            </a-button>
                          </a-menu-item>
                          <a-menu-item key="3">
                            <a-button
                              type="link"
                              size="small"
                              @click="showHelp"
                            >
                              语法示例
                            </a-button>
                          </a-menu-item>
                        </a-menu>
                        <a-icon
                          slot="icon"
                          type="down"
                          style="font-size: 12px"
                        />
                      </a-dropdown-button>

                      <a-select
                        v-model="pane.limitSql"
                        size="small"
                        class="limitSqlSelect"
                      >
                        <a-select-option key="1" value="LIMIT 100">
                          LIMIT 100
                        </a-select-option>
                        <a-select-option key="1" value="LIMIT 200">
                          LIMIT 200
                        </a-select-option>
                        <a-select-option key="1" value="LIMIT 300">
                          LIMIT 300
                        </a-select-option>
                        <a-select-option key="1" value="">
                          不限制
                        </a-select-option>
                      </a-select>

                      <a-button
                        size="small"
                        @click="submitChanges()"
                        v-if="
                          user_power &&
                          databaseType !== 'REDIS' &&
                          submitChangesShow
                        "
                        :disabled="!databaseId"
                      >
                        <svg-icon icon-class="xiugai" />提交修改</a-button
                      >
                      <a-button
                        size="small"
                        style="margin-right: 10px"
                        v-if="
                          user_power &&
                          databaseType !== 'REDIS' &&
                          submitChangesShow
                        "
                        @click="cancelChanges()"
                      >
                        <a-icon type="rollback" />撤销修改
                      </a-button>
                    </div>
                    <div
                      :id="'tx_' + pane.key"
                      @mousedown="dragEagleText"
                      class="tx_div"
                    ></div>
                    <div :id="'resultTableId_' + pane.key" class="resultTable">
                      <vueFastTable
                        :rowKey="queryTableKey"
                        type="query"
                        :clickType="clickType"
                        :userPower="user_power"
                        :columns="queryColumns[pane.key]"
                        :dataSource="queryData[pane.key]"
                        :originalSqlData="originalSqlData[pane.key]"
                        :tableIndex="tableIndex[pane.key]"
                        :editableTabsValue="editableTabsValue"
                        @rowCell="rowCellClick"
                        @valueChange="handleChangeSQL"
                        @change="handleContentTableChange"
                        @addRow="addRow(queryColumns, queryData, queryTableKey)"
                        @deleteRow="deleteRow(queryData)"
                        @lineDetails="lineDetails"
                        @exportExcel="exportExcel('EXCEL', loadingSql)"
                      />
                    </div>
                  </template>
                  <!-- 内容 -->
                  <div
                    class="contentTable"
                    :id="'contentId_' + pane.key"
                    style="max-width: 100%"
                    :ref="'contentTable' + pane.key"
                    v-if="
                      pane.key.substring(0, pane.key.indexOf('_')) === 'content'
                    "
                  >
                    <vueFastTable
                      :rowKey="tableKey"
                      type="content"
                      :clickType="clickType"
                      :userPower="user_power"
                      :columns="contentColumnsList[pane.key]"
                      :dataSource="contentTableData[pane.key]"
                      :originalSqlData="originalSqlData[pane.key]"
                      :tableIndex="tableIndex[pane.key]"
                      :editableTabsValue="editableTabsValue"
                      @rowCell="rowCellClick"
                      @valueChange="valueTableChange"
                      @change="handleContentTableChange"
                      @addRow="
                        addRow(contentColumnsList, contentTableData, tableKey)
                      "
                      @copyRow="copyRow(contentTableData, tableKey)"
                      @deleteRow="deleteRow(contentTableData)"
                      @refreshContentTable="refreshContentTable"
                    />
                  </div>
                  <!-- 表结构 -->
                  <div
                    class="structureTable"
                    v-if="
                      pane.key.substring(0, pane.key.indexOf('_')) ===
                      'structure'
                    "
                  >
                    <div class="structureTableTop">
                      <vueFastTable
                        :selectContent="true"
                        :rowKey="sqlTableKey"
                        type="structure"
                        :clickType="clickType"
                        :userPower="user_power"
                        :columns="sqlColumnsList[pane.key]"
                        :dataSource="sqlTableList[pane.key]"
                        :columnOptionsMap="sqlColumnOptionsMap[pane.key]"
                        :originalSqlData="originalSqlData[pane.key]"
                        :tableIndex="tableIndex[pane.key]"
                        :editableTabsValue="editableTabsValue"
                        @rowCell="rowCellClick"
                        @valueChange="structureTableChange"
                        @addRow="
                          addRow(sqlColumnsList, sqlTableList, sqlTableKey)
                        "
                        @copyRow="copyRow(sqlTableList, sqlTableKey)"
                        @deleteRow="deleteRow(sqlTableList)"
                      />
                    </div>
                    <div class="structureTableBottom">
                      <div class="bar">
                        <div class="cx_button">
                          <a-button
                            size="small"
                            @click="submitChanges()"
                            v-if="
                              user_power &&
                              databaseType !== 'REDIS' &&
                              submitChangesShow
                            "
                            :disabled="!databaseId"
                          >
                            <svg-icon icon-class="xiugai" />提交修改</a-button
                          >
                          <a-button
                            size="small"
                            style="margin-right: 10px"
                            v-if="
                              user_power &&
                              databaseType !== 'REDIS' &&
                              submitChangesShow
                            "
                            @click="cancelChanges()"
                          >
                            <a-icon type="rollback" />撤销修改
                          </a-button>
                        </div>
                        <div class="title">
                          <div>索引列表</div>
                          <div>建表语句</div>
                        </div>
                      </div>
                      <div class="indexTable">
                        <div class="indexTableLeft">
                          <vueFastTable
                            :selectContent="true"
                            type="INDEXES"
                            :clickType="clickType"
                            :rowKey="implementTableKey"
                            :userPower="user_power"
                            :columns="implementColumnList[pane.key]"
                            :dataSource="implementList[pane.key]"
                            :columnOptionsMap="
                              implementColumnOptionsMap[pane.key]
                            "
                            :originalSqlData="originalImplementData[pane.key]"
                            :tableIndex="implementTableIndex[pane.key]"
                            :editableTabsValue="editableTabsValue"
                            @rowCell="implementRowCellClick"
                            @valueChange="structureIndexTableChange"
                            @addRow="
                              addRow(
                                implementColumnList,
                                implementList,
                                implementTableKey,
                                'INDEXES'
                              )
                            "
                            @copyRow="
                              copyRow(
                                implementList,
                                implementTableKey,
                                'INDEXES'
                              )
                            "
                            @deleteRow="deleteRow(implementList, 'INDEXES')"
                          />
                        </div>
                        <div class="indexTableRight">
                          <a-textarea v-model="createTableSql" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </a-tab-pane>
            </a-tabs>
            <!-- 命令行面板 -->
            <operation-panel
              :responseStatement="responseStatement"
              :operationShow="operationShow"
            ></operation-panel>
          </a-layout-content>
          <!-- 页面底部 -->
          <a-layout-footer
            class="footer"
            style="
              height: 37px;
              line-height: 37px;
              color: #333;
              background: #ededed;
              padding: 0 20px 0 0;
              width: 100%;
              z-index: 1;
            "
          >
            <div style="padding: 0px 10px; display: inline-block">
              <a-button
                size="small"
                style="margin-right: 10px"
                v-if="changeTableShow"
                @click="cancelChangeTable"
              >
                <a-icon type="rollback" />撤销修改
              </a-button>
              <a-button
                size="small"
                style="margin-right: 10px"
                v-if="changeTableShow"
                @click="changeTable"
              >
                <svg-icon icon-class="xiugai" />提交修改
              </a-button>
              <a-button
                size="small"
                @click="submitChanges()"
                v-if="
                  user_power &&
                  editableTabsValue.substring(
                    0,
                    editableTabsValue.indexOf('_')
                  ) === 'content' &&
                  submitChangesShow
                "
                :disabled="!databaseId || databaseType == 'REDIS'"
                style="margin-right: 10px"
              >
                <svg-icon icon-class="xiugai" />提交修改</a-button
              >
              <a-button
                size="small"
                v-if="
                  user_power &&
                  editableTabsValue.substring(
                    0,
                    editableTabsValue.indexOf('_')
                  ) === 'content' &&
                  submitChangesShow
                "
                @click="cancelChanges()"
              >
                <a-icon type="rollback" />撤销修改
              </a-button>
            </div>
            <div class="pagination_info">
              <span
                v-if="
                  editableTabsValue.substring(
                    0,
                    editableTabsValue.indexOf('_')
                  ) === 'content' && totalMap[editableTabsValue] > 0
                "
              >
                {{ offsetMap[editableTabsValue] }} -
                {{ limitMap[editableTabsValue] }} of ~
                {{ totalMap[editableTabsValue] }} rows
              </span>
            </div>

            <a-tooltip>
              <template slot="title">
                {{ operationShow ? "收起" : "展开" }}底部面板
              </template>
              <a-button
                style="float: right"
                class="button"
                @click="upClick"
                type="link"
              >
                <icon-font type="icon-zhankai2" v-if="operationShow" />
                <icon-font type="icon-shouqi2" v-else />
              </a-button>
            </a-tooltip>
            <div
              v-if="
                editableTabsValue.substring(
                  0,
                  editableTabsValue.indexOf('_')
                ) === 'content'
              "
              class="pagination_div"
            >
              <a-button
                class="button"
                icon="left"
                size="small"
                type="link"
                @click="turnPage('per')"
                :disabled="turnPagePreviousDisabled"
              ></a-button>
              <a-button
                class="button"
                icon="right"
                size="small"
                type="link"
                @click="turnPage('next')"
                :disabled="turnPageNextDisabled"
              ></a-button>
            </div>
          </a-layout-footer>
        </a-layout>
      </a-layout>
    </a-layout>
    <!-- 单行详情 -->
    <a-modal
      title="单行详情"
      v-model="lineDetailsShow"
      :maskClosable="false"
      :afterClose="lineDetailsClose"
      width="1000px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(lineTableColums)"
        :columns="lineTableColums"
        :dataSource="lineTableData[editableTabsValue]"
        size="small"
        :pagination="false"
      >
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            :disabled="lastArticleDis"
            @click="itemButton(1)"
            >上一条</a-button
          >
          <a-button
            type="primary"
            :disabled="nextItemDis"
            @click="itemButton(2)"
            >下一条</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 我的SQL -->
    <a-modal
      title="我的SQL"
      v-model="mySqlShow"
      :maskClosable="false"
      :width="600"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(mySqlColums)"
        :columns="mySqlColums"
        :dataSource="mySqlList"
        size="small"
        :pagination="mySqlPagination"
      >
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="mySqlSee(record)">查看</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="mySqlModify(record)">修改</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="mySqlUse(record)">使用</a>
          <a-divider type="vertical" />
          <a
            href="javascript:;"
            @click="mySqlDelete(record)"
            style="color: #f56c6c"
            >删除</a
          >
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="mySqlCencel">取消</a-button>
          <a-button type="primary" @click="mySqlAdd">新增常用SQL</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 新增sql -->
    <a-modal
      :title="addSqlTitle"
      v-model="addSqlShow"
      :maskClosable="false"
      :afterClose="mySqlClose"
    >
      <a-form>
        <a-form-item
          label="名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="请输入名称" v-model="addSql.sqlName" />
        </a-form-item>
        <a-form-item
          label="sql"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-textarea placeholder="请输入内容" v-model="addSql.sql" :rows="6" />
        </a-form-item>
        <a-form-item
          label="备注"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="addSql.remark"
            :rows="3"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="addSqlCencel">取消</a-button>
          <a-button type="primary" :loading="loading" @click="addSqlSubmit"
            >新增</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 查看我的sql -->
    <a-modal
      title="查看SQL"
      v-model="seeSqlShow"
      :maskClosable="false"
      :afterClose="seeSqlClose"
      :footer="null"
    >
      <a-form>
        <a-form-item
          label="sql"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea v-model="seeSql" :rows="6" readOnly />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 查询导出导入记录 -->
    <a-modal
      title="导出导入记录"
      v-model="importOrExportDataShow"
      :maskClosable="false"
      width="1000px"
    >
      <a-tabs v-model="active" @change="callback">
        <a-tab-pane key="1" tab="导出">
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(exportDataColumns)"
            :columns="exportDataColumns"
            :dataSource="exportDataList"
            size="small"
            :pagination="exportDataPagination"
            style="margin-top: 10px"
            :rowKey="(record) => record.id"
          >
            <span slot="state" slot-scope="text, record" style="width: 100%">
              <a-tag :color="record.tagColor">{{ record.stateLabel }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a
                href="javascript:;"
                @click="download(record)"
                :disabled="record.state !== 'SUCCESS'"
                >下载</a
              >
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="导入">
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(importDataColumns)"
            :columns="importDataColumns"
            :dataSource="importDataList"
            size="small"
            :pagination="importDataPagination"
            style="margin-top: 10px"
          >
            <span slot="status" slot-scope="text, record" style="width: 100%">
              <a-tag :color="record.tagColor">{{ record.statusLabel }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="dumpOperationImport(record)"
                :disabled="record.status !== 'UPLOADED'"
              >
                <template slot="title">是否导入数据？</template>
                <a href="javascript:;" :disabled="record.status !== 'UPLOADED'"
                  >导入数据</a
                >
              </a-popconfirm>
              <a-divider type="vertical" />
              <a
                href="javascript:;"
                @click="updateImportDatabaseSource(record, false)"
                v-if="record.textName.indexOf('.sql') !== -1"
                >恢复</a
              >

              <a href="javascript:;" @click="previewClick(record)" v-else
                >预览</a
              >
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="importOrExportDataShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="恢复"
      v-model="recoverShow"
      v-if="recoverShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item>
          <span slot="label">
            选择实例
            <a-tooltip>
              <template slot="title">
                如需恢复到其他数据库可以选择实例和数据库,默认为空
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            showSearch
            v-model="databaseInfo.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
          >
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据库:">
          <a-select
            v-model="databaseInfo.databaseName"
            placeholder="请选择数据库"
            showSearch
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :disabled="!databaseInfo.instanceId"
          >
            <a-select-option
              v-for="item in databaseList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="recoverShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="updateImportDatabaseSourceSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 查询导入预览 -->
    <a-modal
      title="导入预览"
      v-model="importPreviewShow"
      :maskClosable="false"
      :afterClose="importPreviewClose"
      width="900px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          分割符：
          <a-select
            v-model="importDataSeparatorType"
            placeholder="请选择分隔符"
            style="width: 100px"
            @change="importDataSeparatorTypeChange"
          >
            <a-select-option
              v-for="item in separatorList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-input
            v-if="importDataSeparatorType == ''"
            v-model="importDataSeparator"
            placeholder="请输入分隔符"
            style="width: 200px; margin-left: 10px"
            @pressEnter="getDataImportPreview()"
            @change="allowClearChange"
          />
        </div>
        <div class="searchConditionItem">
          table：
          <a-select
            v-model="tableNameSelect"
            placeholder="请选择table"
            allowClear
            @change="tableNameSelectChange"
          >
            <a-select-option v-for="item in tableList" :key="item.title">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
        <div class="searchConditionItem">
          是否排除第一行：
          <a-checkbox
            v-model="excludeFirstRow"
            @change="getDataImportPreview"
          />
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="getDataImportPreview()">
            预览
          </a-button>
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(importPreviewColumns)"
        :dataSource="importPreviewList"
        size="small"
        :pagination="false"
        bordered
        style="margin-top: 10px"
      >
        <a-table-column
          v-for="item in importPreviewColumns"
          :key="item.dataIndex"
        >
          <span slot="title">
            <div>{{ item.title }}</div>
          </span>
          <div class="up_down_style_import" slot="filterIcon">
            <a-icon type="up" />
            <a-icon type="down" />
          </div>
          <div
            slot="filterDropdown"
            slot-scope="{ column }"
            style="padding: 8px"
          >
            <ul
              v-if="importPreviewHeaderList.length > 0"
              class="importPreviewHeader_class"
            >
              <li
                v-for="menu in importPreviewHeaderList"
                :key="menu.columnName"
                @click="headerChange(column.key, menu.columnName)"
              >
                {{ menu.columnName }}
              </li>
            </ul>
            <a-empty v-else :image="simpleImage" />
          </div>
          <template slot-scope="text, record">
            {{ record[item.dataIndex] }}
          </template>
        </a-table-column>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="importPreviewShow = false">关闭</a-button>
          <a-button type="primary" @click="importPreview_submit">确定</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 键盘快捷方式 -->
    <a-modal
      title="键盘快捷方式设置"
      v-model="keyboardShow"
      :maskClosable="false"
      :footer="null"
      :pagination="false"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(keyboardColums)"
        :columns="keyboardColums"
        :dataSource="keyboardList"
        size="small"
        :pagination="false"
      >
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="modifyKey(record)">修改</a>
        </span>
      </a-table>
    </a-modal>
    <!-- 修改键盘快捷方式 -->
    <a-modal
      title="输入所需的组合键,再按Enter键"
      v-model="modifyKeyShow"
      :maskClosable="false"
      :afterClose="keyboardClose"
      :footer="null"
      class="input-key"
    >
      <a-input
        v-model="inputKey"
        @keydown.native="keyText($event)"
        class="keyInput"
      />
      <div style="text-align: center">
        {{ keysWord }}
      </div>
    </a-modal>

    <a-modal
      title="导出数据"
      v-model="exportDataShow"
      :maskClosable="false"
      class="addDb-class"
    >
      <a-form>
        <a-form-item
          label="sql"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          style="margin-bottom: 5px"
        >
          <a-textarea
            v-model="exportDataForm.sql"
            size="small"
            :auto-size="{ minRows: 4, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
        <a-form-item
          label="格式"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          style="margin-bottom: 0"
        >
          <a-select
            v-model="exportDataForm.format"
            placeholder="请选择"
            size="small"
          >
            <a-select-option v-for="item in formatList" :key="item.format">{{
              item.format
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="exportDataShow = false">取消</a-button>
          <a-button type="primary" @click="exportData_submit">导出</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="导入数据"
      v-model="importDataShow"
      :maskClosable="false"
      class="addDb-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="导入文件">
          <a-upload-dragger
            :action="importDataUrl"
            :multiple="true"
            @change="importDataUploadChange"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">导入</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="importDataShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <!-- sql审核  -->
    <a-modal
      title="sql审核"
      v-model="sqlAuditShow"
      :maskClosable="false"
      width="600px"
    >
      <a-form>
        <a-form-item
          label="选择实例:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-input v-model="selectDatabase" disabled />
        </a-form-item>
        <a-form-item
          label="数据库名:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          class="dbNameClass"
        >
          <a-input v-model="databaseName" disabled />
        </a-form-item>
        <a-form-item
          label="执行sql:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-textarea
            v-model="sqlAuditContent"
            placeholder="请输入执行sql"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
        <a-form-item
          label="执行时间:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <div>
            <a-checkbox :checked="isImplement" @change="onChangeTiming">
              定时执行
            </a-checkbox>
            <a-date-picker
              v-model="sqlAuditExecuteTime"
              show-time
              placeholder="请选择执行时间"
              format="YYYY-MM-DD HH:mm:ss"
              v-if="isImplement"
            />
          </div>
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-textarea
            v-model="sqlAuditRemark"
            placeholder="请输入备注"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sqlAuditShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="sqlAudit_submit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 添加数据库  -->
    <a-modal
      title="添加数据库"
      v-model="addDatabaseShow"
      :maskClosable="false"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="账号:">
          <a-select
            v-model="addAccountExtraInfoId"
            placeholder="选择数据库"
            @change="databaseConfigChange"
            style="width: 85%"
          >
            <a-select-option
              v-for="item in accountList"
              :key="item.accountExtraInfoId"
              :value="item.accountExtraInfoId"
            >
              {{ item.user }}@{{ item.allowHost }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="数据库:">
          <a-select
            v-model="addDatabaseId"
            placeholder="选择数据库"
            :disabled="!addAccountExtraInfoId"
            @search="getDataBaseList"
            showSearch
            :filter-option="false"
            option-filter-prop="children"
            style="width: 85%"
          >
            <a-select-option
              v-for="item in userDatabaseList"
              :key="item.databaseId"
              :value="item.databaseId"
              :disabled="addDatabaseDisabled(item.databaseId)"
            >
              {{ item.databaseName }}
            </a-select-option>
          </a-select>
          <a-tooltip>
            <template slot="title"> 刷新 </template>
            <a-button type="link" icon="sync" @click="refreshDatabaseConfig" />
          </a-tooltip>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addDatabaseShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="addDatabaseSubmit"
            :disabled="!addDatabaseId"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="当前数据库活跃进程"
      v-model="processListShow"
      :maskClosable="false"
      width="1000px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(processColumns)"
        :columns="processColumns"
        :dataSource="processList"
        size="small"
        :pagination="false"
      >
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="killProcess(record)"
          >
            <template slot="title">是否kill该进程？</template>
            <a href="javascript:;">kill</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="processListShow = false">关闭</a-button>
          <a-button type="primary" @click="showProcessList">刷新</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="创建表"
      v-model="createTableShow"
      :maskClosable="false"
      width="600px"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="表名:">
          <a-input v-model="createTableName" aria-placeholder="请输入表名" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="createTableShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="createTableSubmit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="创建表"
      v-model="showHelpVisible"
      :maskClosable="false"
      :footer="false"
      width="1000px"
    >
      <a-tabs :default-active-key="0" tab-position="left">
        <a-tab-pane
          v-for="(children, tab, key) in showHelpMap"
          :key="key"
          :tab="tab"
        >
          <div v-for="item in children" style="margin-bottom: 30px">
            {{ item.describe }}<br /><br />
            {{ item.sql }}
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <a-modal
      title="异步执行任务"
      v-model="listAsyncSqlJobShow"
      :maskClosable="false"
      :footer="null"
      width="1000px"
    >
      <div class="searchButton">
        <a-button type="primary" icon="sync" @click="listAsyncSqlJob()">
          刷新
        </a-button>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(asyncSqlJobColumns)"
        :columns="asyncSqlJobColumns"
        :dataSource="asyncSqlJobList"
        size="small"
        :loading="asyncSqlJobLoading"
      >
        <span slot="sql" slot-scope="text, record" class="slotCell">
          <span class="content">{{ text }}</span>
          <a-tooltip>
            <template slot="title"> 查看sql </template>
            <a-button
              type="link"
              icon="eye"
              size="small"
              @click="viewSql(record)"
            />
          </a-tooltip>
        </span>
        <span slot="running" slot-scope="text, record" style="width: 100%">
          <a-tag :color="text ? 'orange' : 'green'">
            {{ text ? "运行中 " : "已完成" }}
          </a-tag>
        </span>
        <span slot="endAt" slot-scope="text, record" style="width: 100%">
          {{ record.running ? "-" : text }}
        </span>
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a
            href="javascript:;"
            :disabled="record.running"
            @click="viewResult(record)"
            >查看结果</a
          >
        </span>
      </a-table>
    </a-modal>

    <a-modal
      title="查看SQL"
      v-model="viewSqlShow"
      :maskClosable="false"
      :footer="false"
      width="800px"
    >
      {{ viewSqlText }}
    </a-modal>
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import * as api from "../lib/sql.js";
import { showDatabaseForSuper, instanceList } from "../lib/databaseList.js";
import "codemirror/theme/ambiance.css";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";
const sqlFormatter = require("sql-formatter");

const CodeMirror = require("codemirror/lib/codemirror");
require("codemirror/addon/edit/matchbrackets");
require("codemirror/addon/edit/closebrackets");
require("codemirror/addon/selection/active-line"); //设置光标所在行高亮
require("codemirror/mode/sql/sql");
require("codemirror/addon/hint/show-hint");

import operationPanel from "../components/operationPanel.vue";
import vueFastTable from "../components/vueFastTable.vue";

import "@/assets/icons/zhixing.svg";
import "@/assets/icons/geshihua.svg";
import "@/assets/icons/daochu.svg";
import "@/assets/icons/daoru.svg";
import "@/assets/icons/xinzeng.svg";
import "@/assets/icons/xiugai.svg";
import "@/assets/icons/jihua.svg";
import { MD5 } from "crypto-js";
export default {
  name: "sql",
  components: {
    operationPanel,
    vueFastTable,
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  data() {
    return {
      databaseType: "",
      loading: false,
      addSqlTitle: "",
      sqlId: "",
      importPreviewHeaderList: [],
      importDataUrl:
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/dumpOperation/uploadFile",
      importOrExportDataShow: false,
      active: "1",
      importDataList: [],
      importDataColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "textName",
        },
        {
          title: "文件条数",
          ellipsis: true,
          dataIndex: "totalCount",
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "length",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "导入时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 160,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      importDataPagination: {},
      exportDataList: [],
      exportDataColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
        },
        {
          title: "条数",
          ellipsis: true,
          dataIndex: "currentCount",
        },
        {
          title: "数据库名",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "导出时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 160,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      exportDataPagination: {},
      exportPageNo: 1,
      importPreviewShow: false,
      importPreviewColumns: [],
      importPreviewList: [],
      importPageNo: 1,
      importDataSeparatorType: "",
      importDataSeparator: "",
      tableNameSelect: undefined,
      excludeFirstRow: false,
      importAccountExtraInfoId: "",
      importDataId: "",
      sqlAuditExecuteTime: null,
      isImplement: false,
      sqlAuditRemark: "",
      instanceId: undefined,
      selectDatabase: "",
      sqlAuditShow: false,
      sqlAuditContent: "",
      responseStatement: {},
      operationShow: false,
      leftShow: true,
      implementTableIndex: {},
      originalImplementData: {},
      implementSqlData: {},
      originalSqlData: {},
      sub: 0,
      lineDetailsShow: false,
      lastArticleDis: false,
      nextItemDis: false,
      clickDisabled: true,
      tableKey: "_id_",
      queryTableKey: "_id_",
      sqlTableKey: "_id_",
      implementTableKey: "_id_",
      queryColumns: {
        0: [],
      },
      queryData: {
        0: [],
      },
      submitChangesShow: false,
      contentColumnsList: {},
      contentTableData: {},
      sqlTableList: {},
      sqlColumnOptionsMap: {},
      sqlColumnsList: {},
      implementColumnList: {},
      implementColumnOptionsMap: {},
      implementList: {},
      code: "",
      userName: "",
      loginAvatarUrl: "",
      databaseId: undefined,
      databaseName: "",
      accountExtraInfoId: undefined,
      userDatabaseList: [],
      accountList: [],
      instanceList: [],
      databaseList: [],
      addFieldsShow: false,
      editFieldsShow: false,
      querySql: "",
      exportDataForm: {
        sql: "",
        format: "EXCEL",
      },
      formatList: [
        {
          id: 1,
          format: "EXCEL",
        },
        {
          id: 2,
          format: "CSV",
        },
        {
          id: 3,
          format: "SQL",
        },
      ],
      editableTabsValue: "query_0",
      data: [],
      tableList: [],
      editor: {},
      keyboardColums: [
        {
          title: "命令",
          ellipsis: true,
          dataIndex: "command",
          align: "center",
        },
        {
          title: "绑定键",
          ellipsis: true,
          dataIndex: "keyBinding",
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      keyboardList: [
        {
          key: 1,
          command: "删除行",
          keyBinding: "Ctrl-D",
          extra: "deleteLine",
        },
        {
          key: 2,
          command: "自动补全",
          keyBinding: "Tab",
          extra: "autocomplete",
        },
        {
          key: 3,
          command: "选中自动执行",
          keyBinding: "Ctrl-R",
          extra: (cm) => {
            this.implementSql();
          },
        },
      ],
      sqlKeys: [
        "SELECT",
        "INSERT",
        "UPDATE",
        "join",
        "on",
        "FROM",
        "WHERE",
        "set",
        "AND",
        "SHOW",
        "DESC",
        "CREATE",
        "ORDER",
        "BY",
        "ASC",
        "LIMIT",
        "TABLE",
        "TABLES",
        "LIKE",
        "SUM",
        "COUNT",
        "DISTINCT",
        "left",
        "right",
        "OR",
        "MAX",
        "MIN",
      ],
      tableNameKeys: [],
      keys: [],
      tips: [],
      ch: "",
      sqlTableStructure: "",
      // 表信息字段
      inputType: "",
      inputEncoding: "",
      inputCollation: "",
      createdAt: "",
      updatedAt: "",
      rows: "",
      rowFormat: "",
      autoIncrement: "",
      dataSize: "",
      infoTextarea: "",
      mySqlList: [],
      mySqlColums: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 50,
        },
        {
          title: "SQL名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      addSqlShow: false,
      seeSqlShow: false,
      seeSql: "",
      mySqlPagination: {},
      mySqlShow: false,
      addSql: {
        sqlName: "",
        sql: "",
        remark: "",
      },
      mysqlpageNo: 1,
      keyboardShow: false,
      inputKey: "",
      keysWord: "",
      modifyKeyShow: false,
      bindingKey: "",
      nodeLabelShow: "",
      nodeLabel: "",
      // 底边栏
      exportDataShow: false,
      importDataShow: false,
      tableIndex: {},
      lineTableData: {},
      lineTableColums: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 50,
        },
        {
          title: "列",
          ellipsis: true,
          dataIndex: "oneLine",
        },
        {
          title: "值",
          ellipsis: true,
          dataIndex: "twoLine",
        },
      ],
      sqlPanes: [],
      newTabIndex: 1,
      tabCreated: {}, // 已创建的tab
      editWindowShow: true, // 窗口创建
      loadingShow: false, // 过度动画
      sqlTableName: {}, // sql窗口返回的表名称
      user_power: true, // 数据库操作权限
      clickType: true,
      offsetMap: {}, // 分页页码
      limitMap: {}, // 查询条数
      totalMap: {}, // 总条数
      turnPagePreviousDisabled: true, // 翻页上一页禁用
      turnPageNextDisabled: true, // 翻页下一页禁用
      searchValue: "", // 模糊搜索关键字
      last_ch_string: "",
      hintFieldList: {},
      addAccountExtraInfoId: undefined,
      addDatabaseId: undefined,
      addDatabaseShow: false,
      databaseTabList: [],
      headerTitle: "",
      tableTitle: "",
      color: "",
      tag: "",
      checkTable: "",
      keydownTimeOut: null,
      tableCtrlClickList: [],
      tableShiftClickList: [],
      tableShiftClickStartIndex: -1,
      tableShiftClickEndIndex: -1,
      databaseTabIndex: -1,
      databaseData: null,
      setCookieTimer: null,
      separatorList: [
        {
          label: "逗号",
          value: ",",
        },
        {
          label: "空格",
          value: " ",
        },
        {
          label: "Tab",
          value: "	",
        },
        {
          label: "自定义",
          value: "",
        },
      ],
      databaseInfo: {
        instanceId: undefined,
        databaseName: undefined,
        id: "",
      },
      ids: [],
      recoverShow: false,
      defaultValueMap: {},
      defaultValueIndexMap: {},
      fetchResultTimerMap: {},
      processColumns: [
        {
          title: "ID",
          dataIndex: "ID",
        },
        {
          title: "USER",
          dataIndex: "USER",
        },
        {
          title: "HOST",
          dataIndex: "HOST",
        },
        {
          title: "DB",
          dataIndex: "DB",
        },
        {
          title: "COMMAND",
          dataIndex: "COMMAND",
        },
        {
          title: "TIME",
          dataIndex: "TIME",
        },
        {
          title: "STATE",
          dataIndex: "STATE",
        },
        {
          title: "INFO",
          dataIndex: "INFO",
          ellipsis: true,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      processList: [],
      processListShow: false,
      executedStatementsTotal: 0,
      executedStatementsCount: 0,
      executedStatementsTime: 0,
      executedStatementsTimer: null,
      createTableName: "",
      createTableShow: false,
      copyTableShow: false,
      createTableSql: "",
      showHelpVisible: false,
      showHelpMap: {},
      reQuerySwitch: false,
      loadingSql: "",
      loadingAsyncExecutionShow: false,
      asyncExecutionShow: false,
      loadingTimer: null,
      loadingSqlTimer: null,
      loadingSqlTime: 0,
      uuid: "",
      cancelToken: null,
      asyncSqlJobColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 80,
          ellipsis: true,
        },
        {
          title: "sql",
          dataIndex: "sql",
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: "sql" },
        },
        {
          title: "状态",
          dataIndex: "running",
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: "running" },
        },
        {
          title: "开始时间",
          dataIndex: "startAt",
          width: 150,
          ellipsis: true,
        },
        {
          title: "结束时间",
          dataIndex: "endAt",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "endAt" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      asyncSqlJobList: [],
      listAsyncSqlJobShow: false,
      asyncSqlJobLoading: false,
      viewSqlShow: false,
      viewSqlText: "",
      implementSqlLoading: false,
      asyncBadgeCount: 0,
      submitOverDumpOperationImport: false,
      allowSqlHintSwitch: false,
      importOrExportCount: 0,
    };
  },
  watch: {
    databaseId(val) {
      // 库选择监听
      this.clickDisabled = true;
    },
    inputKey(val) {
      //快捷键监听
      this.keysWord = "";
      if (val) {
        let keysWord = val.split(" ");
        this.keysWord = [];
        keysWord.map((item) => {
          if (item) {
            item = item.charAt(0).toUpperCase() + item.slice(1);
            this.keysWord.push(item);
          }
        });
        this.keysWord = this.keysWord.join("-");
      }
    },
    tips(newValue) {
      //sql提示内容
      let doHint = true;
      if (newValue && newValue.length > 0) {
        if (this.ch === "") {
          doHint = false;
        }
        if (doHint && this.last_ch_string != this.ch) {
          this.showHint(this.editor[this.editableTabsValue], newValue);
          this.last_ch_string = this.ch;
        }
      }
    },
    editableTabsValue(newValue, oldValue) {
      if (newValue.indexOf("content_") !== -1) {
        let arr = newValue.split("_");
        arr.shift();
        arr.pop();
        this.checkTable = arr.join("_");
      } else {
        this.checkTable = "";
      }
      if (
        newValue !== oldValue &&
        newValue !== localStorage.getItem("editableTabs" + this.databaseId)
      ) {
        this.setCookie(this.databaseId);
      }
    },
    databaseTabList(newValue) {
      localStorage.setItem(
        "databaseTabList" + this.instanceId,
        JSON.stringify(newValue)
      );
    },
  },
  computed: {
    changeTableShow() {
      let arr = this.tableList.filter((item) => {
        return (
          item.drop || item.truncate || item.tableName !== item.newTableName
        );
      });
      if (arr.length == 0) {
        return false;
      }
      return true;
    },
    addDatabaseDisabled() {
      return (databaseId) => {
        return (
          this.databaseTabList.filter((item) => {
            return item.databaseId == databaseId;
          }).length == 1
        );
      };
    },
  },
  async mounted() {
    document.title = "数据库查询系统";
    this.inquireList(true);
    this.userInfoInquire();
    document.body.addEventListener("keydown", this.handleKeydown);
  },
  methods: {
    cancelChangeTable() {
      this.tableList.forEach((item) => {
        this.$set(item, "truncate", false);
        this.$set(item, "drop", false);
        this.$set(item, "newTableName", item.tableName);
      });
    },
    changeTable() {
      this.$confirm({
        title: "确认",
        content: "确认是否提交修改",
        onOk: () => {
          let dropArr = [],
            truncateArr = [],
            renameArr = [];
          this.tableList.forEach((item) => {
            if (item.drop) {
              dropArr.push({
                accountExtraInfoId: this.accountExtraInfoId,
                databaseName: this.databaseName,
                tableName: item.tableName,
              });
            }
            if (item.truncate) {
              truncateArr.push({
                accountExtraInfoId: this.accountExtraInfoId,
                databaseName: this.databaseName,
                tableName: item.tableName,
              });
            }
            if (item.tableName !== item.newTableName) {
              renameArr.push({
                accountExtraInfoId: this.accountExtraInfoId,
                databaseName: this.databaseName,
                originalTableName: item.tableName,
                newTableName: item.newTableName,
              });
            }
          });
          this.$axios
            .all([
              ...dropArr.map((data) => {
                return api.dropTable(data);
              }),
              ...truncateArr.map((data) => {
                return api.truncateTable(data);
              }),
              ...renameArr.map((data) => {
                return api.renameTable(data);
              }),
            ])
            .then((resArr) => {
              resArr.forEach((res) => {
                if (!res.data.success) {
                  this.$message.error(res.data.errorMessage);
                }
                this.responseStatement = {
                  date: res.data.startTime,
                  info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
                  millis: res.data.millis,
                };
              });
              if (dropArr.length > 0) {
                for (const key in this.tabCreated) {
                  let arr = dropArr.filter((item) => {
                    return item.tableName == key.split("_")[1];
                  });
                  if (arr.length > 0) {
                    this.remove(key);
                  }
                }
              }
              this.$message.success("操作成功");
              this.inquireTable(false);
            });
        },
        onCancel() {},
      });
    },
    handleKeydown(event) {
      var targetElement = event.target;
      if (event.keyCode !== 38 && event.keyCode !== 40) {
        return;
      }
      // 检查目标元素是否是输入框或文本区域
      var isInputElement =
        targetElement.tagName === "INPUT" ||
        targetElement.tagName === "TEXTAREA";

      // 如果是输入框或文本区域，阻止事件传播
      if (isInputElement) {
        event.stopPropagation();
      } else {
        clearTimeout(this.keydownTimeOut);
        this.keydownTimeOut = setTimeout(() => {
          let checkTableIndex = -1;
          // 在这里处理 body 上的 keydown 事件
          this.tableList.forEach((item, index) => {
            if (item.tableName == this.checkTable) {
              checkTableIndex = index;
            }
          });
          if (event.keyCode == 38) {
            if (checkTableIndex > 0) {
              checkTableIndex--;
            } else {
              checkTableIndex = this.tableList.length - 1;
            }
          }
          if (event.keyCode == 40) {
            if (checkTableIndex < this.tableList.length - 1) {
              checkTableIndex++;
            } else {
              checkTableIndex = 0;
            }
          }
          this.clickTable(
            this.tableList[checkTableIndex],
            checkTableIndex,
            event
          );
        }, 200);
      }
    },
    upClick() {
      this.operationShow = !this.operationShow;
    },
    leftClick() {
      this.leftShow = !this.leftShow;
    },
    jumpAdmin() {
      this.$router.replace("/admin");
    },
    showProcessList() {
      let data = {
        databaseName: this.databaseName,
        accountExtraInfoId: this.accountExtraInfoId,
      };
      api.showProcesslist(data).then((res) => {
        if (res.result == 200) {
          this.processList = res.data.result;
          this.responseStatement = {
            date: res.data.startTime,
            info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
            millis: res.data.millis,
          };
          this.processListShow = true;
        }
      });
    },
    killProcess(data) {
      let params = {
        databaseName: this.databaseName,
        accountExtraInfoId: this.accountExtraInfoId,
        data,
      };
      api.killProcess(params).then((res) => {
        if (res.result == 200) {
          this.$message.success("操作成功");
          this.showProcessList();
        }
      });
    },
    addDatabase() {
      if (!this.instanceId) {
        this.$message.warning("请选择数据库实例");
        return;
      }
      this.getDataAccountList();
    },
    addDatabaseSubmit() {
      this.userDatabaseList.forEach((item) => {
        if (item.databaseId == this.addDatabaseId) {
          let arr = this.databaseTabList.filter((tabItem) => {
            return tabItem.databaseId == this.addDatabaseId;
          });
          if (arr.length == 0) {
            let account = this.accountList.filter((account) => {
              return account.accountExtraInfoId == this.addAccountExtraInfoId;
            })[0];
            item.userNameLabel = account.user + "@" + account.allowHost;
            this.databaseTabList.push(item);
          }
        }
      });
      this.databaseChange(
        this.databaseTabList[this.databaseTabList.length - 1]
      );
      this.addDatabaseShow = false;
    },
    closeTab(index) {
      this.databaseTabList.splice(index, 1);
      if (this.databaseTabList.length > 0) {
        this.databaseChange(this.databaseTabList[0]);
      } else {
        this.databaseId = undefined;
        this.databaseName = undefined;
        this.databaseType = undefined;
        this.selectDatabase = undefined;
        this.headerTitle = "";
        this.tableTitle = "";
        this.tableList.splice(0);
        this.queryData = { 0: [] };
        this.queryColumns = { 0: [] };
        this.sqlPanes.splice(1, this.sqlPanes.length - 1);
        this.$set(this.sqlPanes[0], "content", "");
        this.sqlHandleClick("query_0");
        this.editor[this.editableTabsValue].setValue("");
        this.editor[this.editableTabsValue].refresh();
      }
    },
    databaseChange(database) {
      if (database.databaseId == this.databaseId) {
        return;
      }
      api
        .allowSqlHint({ accountExtraInfoId: database.accountExtraInfoId })
        .then((res) => {
          if (res.result == 200) {
            this.allowSqlHintSwitch = res.data;
          }
        });
      this.databaseTabList.forEach((item, index) => {
        if (item.databaseId == database.databaseId) {
          this.databaseTabIndex = index;
        }
      });
      localStorage.setItem(
        "databaseTabIndex" + this.instanceId,
        this.databaseTabIndex
      );
      this.editableTabsValue = "query_0";
      //选择数据库表
      this.searchValue = "";
      this.databaseId = database.databaseId;
      this.databaseName = database.databaseName;
      this.accountExtraInfoId = database.accountExtraInfoId;
      this.databaseType = database.databaseType;

      this.selectDatabase = database.host + database.port;
      this.setHeaderTitle(database);
      this.tableTitle = "";
      this.tabCreated = {}; // 清空创建的editor
      this.editWindowShow = false;
      this.inquireTable(false);
      if (this.databaseType === "REDIS") {
        this.clickType = false;
      } else {
        this.clickType = true;
      }
    },
    setHeaderTitle(database) {
      let instance = this.instanceList.filter((item) => {
        return item.id == this.instanceId;
      })[0];
      this.headerTitle =
        instance.databaseType + " " + instance.version + " : " + instance.name;
      if (database) {
        this.headerTitle = this.headerTitle + " : " + database.databaseName;
      }
    },
    getColorAndTag() {
      api
        .getUserDatabaseInstanceConfig({ instanceId: this.instanceId })
        .then((res) => {
          if (res.result == 200) {
            this.color = res.data.color;
            this.tag = res.data.tag;
          }
        });
    },
    setColorAndTag(color, tag) {
      let data = {
        color,
        tag,
        instanceId: this.instanceId,
      };
      if (!color) {
        data.color = this.color;
      } else {
        switch (color) {
          case "white":
            data.color = "#f8f8f8";
            break;
          case "blue":
            data.color = "#bfcbf1";
            break;
          case "yellow":
            data.color = "#f8f7c3";
            break;
          case "green":
            data.color = "#ddebc6";
            break;
          case "red":
            data.color = "#f9d8d5";
            break;
        }
      }
      if (!tag) {
        data.tag = this.tag;
      }
      api.updateUserDatabaseInstanceConfig(data).then((res) => {
        if (res.result == 200) {
          this.color = data.color;
          this.tag = data.tag;
        }
      });
    },
    // 表结构---编辑单元格
    structureTableChange(data) {
      this.$set(this.sqlTableList, this.editableTabsValue, data);
      this.getWhetherItHasChanged(this.sqlTableList);
      this.$forceUpdate();
    },
    // 表结构Index---编辑单元格
    structureIndexTableChange(data) {
      this.$set(this.implementList, this.editableTabsValue, data);
      this.getWhetherItHasChanged(this.implementList);
      this.$forceUpdate();
    },
    // 内容表---编辑单元格
    valueTableChange(data) {
      this.$set(this.contentTableData, this.editableTabsValue, data);
      this.getWhetherItHasChanged(this.contentTableData);
      this.$forceUpdate();
    },
    // INDEX表格---行点击
    implementRowCellClick({ record, rowIndex }) {
      this.implementTableIndex[this.editableTabsValue] = rowIndex;
      this.$forceUpdate();
    },
    // 可编辑表格---行点击
    rowCellClick({ record, rowIndex }) {
      this.tableIndex[this.editableTabsValue] = rowIndex;
      this.sub = rowIndex;
      this.lineTableData[this.editableTabsValue] = record.lineList;
      this.$forceUpdate();
    },
    // 可编辑表格---排序change
    handleContentTableChange({ column, prop, order }) {
      let pageKey = this.editableTabsValue.substring(
        0,
        this.editableTabsValue.indexOf("_")
      ); // 窗口key值
      if (pageKey === "query") {
        let oldSql = this.implementSqlData[this.editableTabsValue]; // 旧的sql语句
        let columnName = prop; // 表头字段
        let sort = order; // 排序分类
        let sorterValue = ""; // 排序sql字段
        let newSql = ""; // 输出sql语句
        let index = oldSql.indexOf("LIMIT");

        let start = "";
        let end = "";
        if (index !== -1) {
          start = oldSql.substring(0, index);
          end = oldSql.substring(index);
        } else {
          start = oldSql;
        }
        if (sort === "asc") {
          sort = "ASC";
        } else if (sort === "desc") {
          sort = "DESC";
        }
        if (sort) {
          sorterValue = "ORDER BY `" + columnName + "` " + sort;
          newSql = start + sorterValue + (end ? " " + end : "");
        } else {
          newSql = oldSql;
        }
        let params = {
          databaseId: this.databaseId,
          databaseName: this.databaseName,
          accountExtraInfoId: this.accountExtraInfoId,
          sql: newSql,
        };
        this.clickExecution(params, true);
      } else if (pageKey === "content") {
        let data = {
          accountExtraInfoId: this.accountExtraInfoId,
          databaseName: this.databaseName,
          tableName: this.editableTabsValue.split("_").slice(1, -1).join("_"),
          offset: 0,
          limit: 300,
        };
        if (order) {
          data.sortColumn = prop;
          data.asc = order == "asc" ? true : false;
          localStorage.setItem(
            this.accountExtraInfoId + "_" + this.editableTabsValue + "Order",
            JSON.stringify({
              sortColumn: data.sortColumn,
              asc: data.asc,
            })
          );
        } else {
          localStorage.removeItem(
            this.accountExtraInfoId + "_" + this.editableTabsValue + "Order"
          );
        }
        this.inquireTableContent(data);
      }
    },
    // sql窗口点击新增
    addRow(columns, dataSource, tableKey, type) {
      if (dataSource[this.editableTabsValue]) {
        let queryData = this.$common.deepClone(
          dataSource[this.editableTabsValue]
        );
        let newData = {};
        let count = queryData.length;
        if (count > 0) {
          for (let i in queryData[0]) {
            newData[i] = "";
            if (i === tableKey) {
              newData[i] = count + 1;
            }
            if (i === "delete_row") {
              newData[i] = false;
            }
            if (i === "edit_text") {
              newData[i] = {};
            }
            if (i === "col") {
              newData[i] = "";
            }
            newData["add_row"] = true;
          }
        } else {
          columns[this.editableTabsValue].forEach((item) => {
            newData[item.columnName] = "";
          });
          newData[tableKey] = count + 1;
          newData["delete_row"] = false;
          newData["edit_text"] = {};
          newData["col"] = "";
          newData["add_row"] = true;
        }
        let defaultValueMap = {};
        if (type == "INDEXES") {
          defaultValueMap = this.defaultValueIndexMap[this.editableTabsValue];
        } else {
          defaultValueMap = this.defaultValueMap[this.editableTabsValue];
        }
        for (const key in defaultValueMap) {
          const value = defaultValueMap[key];
          for (const newKey in newData) {
            if (key == newKey) {
              newData[newKey] = value;
            }
          }
        }
        queryData = [...queryData, newData];
        this.$set(dataSource, this.editableTabsValue, queryData);
        this.tableIndex[this.editableTabsValue] = queryData.length - 1;
        this.getWhetherItHasChanged(dataSource);
        this.$forceUpdate();
      } else {
        this.$message.warning("没有数据");
      }
    },
    copyRow(dataSource, tableKey, type) {
      let queryData = this.$common.deepClone(
        dataSource[this.editableTabsValue]
      );
      let index =
        type == "INDEXES"
          ? this.implementTableIndex[this.editableTabsValue]
          : this.tableIndex[this.editableTabsValue];
      let newData = this.$common.deepClone(queryData[index]);
      let count = queryData.length;
      newData[tableKey] = count + 1;
      newData["delete_row"] = false;
      newData["edit_text"] = {};
      newData["col"] = "";
      newData["add_row"] = true;
      queryData = [...queryData, newData];
      this.$set(dataSource, this.editableTabsValue, queryData);
      this.tableIndex[this.editableTabsValue] = queryData.length - 1;
      this.getWhetherItHasChanged(dataSource);
      this.$forceUpdate();
    },
    // sql窗口点击删除
    deleteRow(dataSource, type) {
      let queryData = this.$common.deepClone(
        dataSource[this.editableTabsValue]
      );
      let index =
        type == "INDEXES"
          ? this.implementTableIndex[this.editableTabsValue]
          : this.tableIndex[this.editableTabsValue];
      if (queryData && typeof index == "number") {
        if (queryData[index].add_row) {
          // 新增数据删除
          queryData.splice(index, 1);
          if (type == "INDEXES") {
            this.implementTableIndex[this.editableTabsValue] =
              queryData.length - 1;
          } else {
            this.tableIndex[this.editableTabsValue] = queryData.length - 1;
          }
        } else {
          queryData[index].delete_row = true;
        }

        this.$set(dataSource, [this.editableTabsValue], queryData);
        this.getWhetherItHasChanged(dataSource);
        this.$forceUpdate();
      } else {
        this.$message.warning("没有数据");
      }
    },
    getWhetherItHasChanged(dataSource) {
      let addRowList = dataSource[this.editableTabsValue].filter((item) => {
        return item.add_row;
      });
      let editRowList = dataSource[this.editableTabsValue].filter((item) => {
        return Object.keys(item.edit_text).length !== 0;
      });
      let deleteRowList = dataSource[this.editableTabsValue].filter((item) => {
        return item.delete_row;
      });
      if (
        addRowList.length > 0 ||
        editRowList.length > 0 ||
        deleteRowList.length > 0
      ) {
        this.submitChangesShow = true;
      } else {
        this.submitChangesShow = false;
      }
    },
    // sql窗口点击提交修改
    submitChanges() {
      this.executedStatementsTotal = 0;
      this.executedStatementsCount = 0;
      this.executedStatementsTime = 0;
      if (this.executedStatementsTimer) {
        clearInterval(this.executedStatementsTimer);
        this.executedStatementsTimer = null;
      }

      this.$confirm({
        title: "确认",
        content: "确认是否提交修改",
        onOk: () => {
          let submitArr = [];
          if (
            this.editableTabsValue.substring(
              0,
              this.editableTabsValue.indexOf("_")
            ) === "query"
          ) {
            submitArr.push(
              ...this.calculateDiffArr(
                this.queryData[this.editableTabsValue],
                this.queryTableKey,
                "query"
              )
            );
          } else if (
            this.editableTabsValue.substring(
              0,
              this.editableTabsValue.indexOf("_")
            ) === "content"
          ) {
            submitArr.push(
              ...this.calculateDiffArr(
                this.contentTableData[this.editableTabsValue],
                this.tableKey,
                "content"
              )
            );
          } else if (
            this.editableTabsValue.substring(
              0,
              this.editableTabsValue.indexOf("_")
            ) === "structure"
          ) {
            submitArr.push(
              ...this.calculateDiffArr(
                this.sqlTableList[this.editableTabsValue],
                this.sqlTableKey,
                "structure"
              )
            );
            submitArr.push(
              ...this.calculateDiffArr(
                this.implementList[this.editableTabsValue],
                this.implementTableKey,
                "index"
              )
            );
          }
          this.executedStatementsTimer = setInterval(() => {
            this.executedStatementsTime++;
          }, 1000);

          this.$axios.all(submitArr).then((resArr) => {
            resArr.forEach((res) => {
              if (typeof res.data == "string") {
                this.fetchResult(res.data);
              } else {
                if (!res.data.success) {
                  this.$message.error(res.data.errorMessage);
                }
                this.responseStatement = {
                  date: res.data.startTime,
                  info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
                  millis: res.data.millis,
                };
              }
            });
            this.cancelChanges();
          });
        },
        onCancel() {},
      });
    },
    fetchResult(uuid) {
      api
        .fetchResult({ uuid })
        .then((res) => {
          if (res.result == 200) {
            if (!res.data) {
              this.fetchResultTimerMap[uuid] = setTimeout(() => {
                this.fetchResult(uuid);
              }, 2000);
            } else {
              this.executedStatementsCount++;
              if (
                this.executedStatementsCount == this.executedStatementsTotal
              ) {
                this.executedStatementsTotal = 0;
                this.executedStatementsCount = 0;
                clearInterval(this.executedStatementsTimer);
                this.executedStatementsTimer = null;
                this.executedStatementsTime = 0;
              }
              if (this.fetchResultTimerMap.hasOwnProperty(uuid)) {
                clearTimeout(this.fetchResultTimerMap[uuid]);
                delete this.fetchResultTimerMap[uuid];
              }
              if (!res.data.success) {
                this.$message.error(res.data.errorMessage);
              }
              this.responseStatement = {
                date: res.data.startTime,
                info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
                millis: res.data.millis,
              };
              this.cancelChanges();
            }
          }
        })
        .catch(() => {
          if (this.fetchResultTimerMap.hasOwnProperty(uuid)) {
            clearTimeout(this.fetchResultTimerMap[uuid]);
            delete this.fetchResultTimerMap[uuid];
          }
        });
    },
    calculateDiffArr(tableList, tableKey, type) {
      let originalData = [];
      if (type == "index") {
        originalData = this.originalImplementData[this.editableTabsValue];
      } else {
        originalData = this.originalSqlData[this.editableTabsValue];
      }
      let deleteArr = [],
        addArr = [],
        editArr = [];
      tableList.forEach((item, index) => {
        let params = {
          accountExtraInfoId: this.accountExtraInfoId,
          databaseName: this.databaseName,
          databaseId: this.databaseId,
          tableName: this.sqlTableName[this.editableTabsValue],
        };
        let data = this.$common.deepClone(item);
        delete data[tableKey];
        delete data.edit_text;
        delete data.delete_row;
        delete data.add_row;
        delete data.col;
        delete data.lineList;
        delete data._XID;

        if (item.delete_row) {
          params.data = data;
          deleteArr.push(params);
        } else if (item.add_row) {
          for (const key in data) {
            const element = data[key];
            if (!element) {
              delete data[key];
            }
          }
          params.data = data;
          addArr.push(params);
        } else if (Object.keys(item.edit_text).length > 0) {
          let before = this.$common.deepClone(originalData[index]);
          delete before[tableKey];
          delete before.edit_text;
          delete before.delete_row;
          delete before.add_row;
          delete before.col;
          delete before.lineList;
          delete before._XID;
          params.before = before;
          params.after = data;
          editArr.push(params);
        }
      });
      let submitArr = [];
      deleteArr.forEach((params) => {
        if (type == "query" || type == "content") {
          submitArr.push(api.deleteOneRow(params));
        } else if (type == "structure") {
          submitArr.push(api.deleteTableColumn(params));
          this.executedStatementsTotal++;
        } else {
          submitArr.push(api.dropIndex(params));
        }
      });
      addArr.forEach((params) => {
        if (type == "query" || type == "content") {
          submitArr.push(api.insertOneRow(params));
        } else if (type == "structure") {
          submitArr.push(api.insertTableColumn(params));
          this.executedStatementsTotal++;
        } else {
          submitArr.push(api.createIndex(params));
        }
      });
      editArr.forEach((params) => {
        if (type == "query" || type == "content") {
          submitArr.push(api.updateOneRow(params));
        } else if (type == "structure") {
          submitArr.push(api.updateTableColumn(params));
          this.executedStatementsTotal++;
        } else {
          submitArr.push(api.modifyIndex(params));
        }
      });
      return submitArr;
    },
    cancelChanges() {
      this.reQuerySwitch = true;
      this.submitChangesShow = false;
      if (
        this.editableTabsValue.substring(
          0,
          this.editableTabsValue.indexOf("_")
        ) === "query"
      ) {
        let params = {
          databaseId: this.databaseId,
          databaseName: this.databaseName,
          accountExtraInfoId: this.accountExtraInfoId,
          sql: this.implementSqlData[this.editableTabsValue],
        };
        this.sqlCheck(params);
      } else if (
        this.editableTabsValue.substring(
          0,
          this.editableTabsValue.indexOf("_")
        ) === "content"
      ) {
        let param = {
          accountExtraInfoId: this.accountExtraInfoId,
          databaseName: this.databaseName,
          tableName: this.editableTabsValue.split("_").slice(1, -1).join("_"),
          offset: this.offsetMap[this.editableTabsValue],
          limit: this.offsetMap[this.editableTabsValue] + 300,
        };
        this.inquireTableContent(param);
      } else if (
        this.editableTabsValue.substring(
          0,
          this.editableTabsValue.indexOf("_")
        ) === "structure"
      ) {
        let params = {
          accountExtraInfoId: this.accountExtraInfoId,
          databaseName: this.databaseName,
          tableName: this.editableTabsValue.split("_").slice(1, -1).join("_"),
        };
        this.inquireTableStructure(params);
      }
    },
    // 编辑sql窗口表格单元格
    handleChangeSQL(data) {
      this.$set(this.queryData, this.editableTabsValue, data);
      this.getWhetherItHasChanged(this.queryData);
      this.$forceUpdate();
    },
    // 读取cookie创建sql窗口
    sqlCreated(databaseId) {
      let tabsList = "";
      let sqlContent = "";
      let editableTabsValue = "";
      let sign = localStorage.getItem("sign" + databaseId);
      let data = {
        accountExtraInfoId: this.accountExtraInfoId,
        category: "databaseData" + databaseId,
        sign,
      };
      api.loadChangedCache(data).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.databaseData = JSON.parse(res.data);
            tabsList = this.databaseData["tabs" + databaseId];
            sqlContent = this.databaseData["sqlContent" + databaseId];
          } else {
            tabsList = localStorage.getItem("tabs" + databaseId);
            sqlContent = localStorage.getItem("sqlContent" + databaseId);
          }

          if (tabsList || sqlContent) {
            this.sqlPanes = [];
            this.queryColumns = {};
            this.queryData = {};
            tabsList = JSON.parse(tabsList);
            sqlContent = JSON.parse(sqlContent);
            let panes = [];
            for (let i in tabsList) {
              for (let j in sqlContent) {
                if (i === j) {
                  let key = tabsList[i].substring(0, tabsList[i].indexOf("_"));
                  let keyName = tabsList[i].substring(
                    0,
                    tabsList[i].lastIndexOf("_") + 1
                  );
                  let name = tabsList[i].substring(
                    tabsList[i].indexOf("_") + 1,
                    tabsList[i].lastIndexOf("_")
                  );
                  switch (key) {
                    case "query":
                      panes.push({
                        title: `SQL窗口`,
                        key: keyName + i,
                        content: sqlContent[j],
                        closable: i == 0 ? false : true,
                        limitSql: "LIMIT 100",
                      });
                      break;
                    case "content":
                      panes.push({
                        title: `内容: ${name}`,
                        key: keyName + i,
                        content: sqlContent[j],
                      });
                      break;
                    case "structure":
                      panes.push({
                        title: `表结构: ${name}`,
                        key: keyName + i,
                        content: sqlContent[j],
                      });
                      break;
                  }
                  this.queryColumns[i] = [];
                  this.queryData[i] = [];
                }
              }
            }
            this.sqlPanes = panes;
            this.newTabIndex = this.sqlPanes.length;
          } else {
            this.sqlPanes = [
              {
                title: "SQL窗口",
                key: "query_0",
                content: "",
                closable: false,
                limitSql: "LIMIT 100",
              },
            ];
          }
          this.editWindowShow = true;
          if (this.sqlPanes.length > 1) {
            // 获取上次停留的页面
            if (res.data) {
              editableTabsValue =
                this.databaseData["editableTabs" + databaseId];
            } else {
              editableTabsValue = localStorage.getItem(
                "editableTabs" + databaseId
              );
            }
            if (editableTabsValue) {
              this.editableTabsValue = editableTabsValue;
            }
          }
          this.sqlHandleClick(this.editableTabsValue);
        }
      });
    },
    // tab新增和删除页签的回调
    onEdit(targetKey, action) {
      this[action](targetKey);
    },
    // 新增tabs
    add(key) {
      const panes = this.sqlPanes;
      let activeKey = "";
      if (key === "content_") {
        activeKey = `content_${this.sqlTableStructure}_${this.newTabIndex++}`;
        panes.push({
          title: `内容: ${this.sqlTableStructure}`,
          key: activeKey,
          content: "",
        });
      } else if (key === "structure_") {
        activeKey = `structure_${this.sqlTableStructure}_${this.newTabIndex++}`;
        panes.push({
          title: `表结构: ${this.sqlTableStructure}`,
          key: activeKey,
          content: this.sqlTableStructure,
        });
      } else {
        activeKey = `query_${this.newTabIndex++}`;
        panes.push({
          title: `SQL窗口`,
          key: activeKey,
          content: "",
          limitSql: "LIMIT 100",
        });
      }
      this.sqlPanes = panes;
      this.editableTabsValue = activeKey;
      this.sqlHandleClick(this.editableTabsValue);
      this.setCookie(this.databaseId);
    },
    // 删除tabs
    remove(targetKey, noChange) {
      let activeKey = this.editableTabsValue;
      let lastIndex;
      this.sqlPanes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.sqlPanes.filter((pane) => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      delete this.tabCreated[targetKey];
      this.sqlPanes = panes;
      this.editableTabsValue = activeKey;
      if (!noChange) {
        this.sqlHandleClick(this.editableTabsValue);
        this.setCookie(this.databaseId);
      }
    },
    // 储存cookie
    setCookie(databaseId) {
      if (this.setCookieTimer) {
        clearTimeout(this.setCookieTimer);
        this.setCookieTimer = null;
      }
      this.setCookieTimer = setTimeout(() => {
        let tabList = []; // tab key
        let contentList = []; // sql内容
        this.sqlPanes.forEach((item) => {
          tabList.push(item.key);
          contentList.push(item.content);
        });
        let key = JSON.stringify(tabList);
        let content = JSON.stringify(contentList);
        // 根据databaseId分别储存cookie
        localStorage.setItem("tabs" + databaseId, key);
        localStorage.setItem("sqlContent" + databaseId, content);
        localStorage.setItem(
          "editableTabs" + this.databaseId,
          this.editableTabsValue
        );
        let databaseData = {};
        databaseData["tabs" + databaseId] = key;
        databaseData["sqlContent" + databaseId] = content;
        databaseData["editableTabs" + databaseId] = this.editableTabsValue;
        let value = JSON.stringify(databaseData);
        let sign = MD5(value).toString();
        localStorage.setItem("sign" + databaseId, sign);

        let data = {
          accountExtraInfoId: this.accountExtraInfoId,
          category: "databaseData" + databaseId,
          value,
          sign,
        };
        api.uploadLocalCache(data);
      }, 1000);
    },
    userInfoInquire() {
      //登录用户信息
      api.userInfo().then((res) => {
        if (res.result == 200) {
          this.userName = res.userName;
          this.loginAvatarUrl = res.avatar;
        }
      });
    },
    logOut() {
      //退出登录
      api.userLogout().then((res) => {
        if (res.result == 200) {
          let url = window.location.href;
          url = url.split("/");
          url = url[0] + "//" + url[2];
          window.location.href = url;
        }
      });
    },
    createdEditor() {
      //创建CodeMirror
      if (this.tabCreated[this.editableTabsValue]) {
        // 如果以创建就retrun
        return;
      }
      let my_editor = document.getElementById(
        "mycode_" + this.editableTabsValue
      );
      // let theme = 'ambiance'//设置主题，不设置的会使用默认主题
      this.editor[this.editableTabsValue] = CodeMirror.fromTextArea(my_editor, {
        mode: { name: "text/x-mysql" }, //选择对应代码编辑器的语言
        indentWithTabs: true, //在缩进时，是否需要把 n*tab宽度个空格替换成n个tab字符
        smartIndent: true, //自动缩进是否开启
        lineNumbers: true, //是否使用行号
        matchBrackets: true, // 括号匹配
        // autoCloseBrackets: true, // 自动关闭()[]{}''""
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        hintOptions: {
          completeSingle: false,
        },
      });
      let keyboardList = JSON.parse(sessionStorage.getItem("keyWord"));
      let editorText = JSON.parse(sessionStorage.getItem("editorText"));
      sessionStorage.removeItem("editorText");
      if (editorText) {
        this.editor[this.editableTabsValue].setValue(editorText);
      }
      if (keyboardList) {
        keyboardList.map((i) => {
          if (i.command == "选中自动执行") {
            i.extra = (cm) => {
              this.implementSql();
            };
          }
        });
        let arr = [];
        this.keyboardList.map((j) => {
          arr.push(j);
        });
        arr.map((c) => {
          c.extra = (cm) => {};
          let shortcutKey = {};
          shortcutKey[c.keyBinding] = c.extra;
          this.editor[this.editableTabsValue].addKeyMap(shortcutKey);
        });
        this.keyboardList = keyboardList;
      }
      this.keyboardList.map((item) => {
        //自定义快捷键
        let a = {};
        a[item.keyBinding] = item.extra;
        this.editor[this.editableTabsValue].addKeyMap(a);
      });
      this.editor[this.editableTabsValue].on("keyup", (cm, event) => {
        if (
          event.keyCode === 13 ||
          event.keyCode === 37 ||
          event.keyCode === 38 ||
          event.keyCode === 39 ||
          event.keyCode === 40 ||
          !this.allowSqlHintSwitch
        ) {
          return;
        }
        //解析sql
        this.tips.splice(0);
        var cursor = cm.getCursor();
        var token = cm.getTokenAt(cursor);
        this.ch = token.string;
        var line = cursor.line;
        let lineContent = cm.getLine(line); //获取第n行的内容

        let startIndex = line;
        while (startIndex > 0) {
          startIndex--;
          const lineContent = cm.getLine(startIndex);
          if (lineContent.includes(";")) {
            startIndex++;
            break;
          }
        }

        // 向下搜索结束位置
        let endIndex = line;
        const totalLines = cm.lineCount();
        while (endIndex < totalLines - 1) {
          endIndex++;
          const lineContent = cm.getLine(endIndex);
          if (lineContent.includes(";")) {
            break;
          }
        }

        // 提取同一句的内容
        let sameSentenceContent = "";
        for (let i = startIndex; i <= endIndex; i++) {
          sameSentenceContent += cm.getLine(i).trim() + " ";
        }

        let start = token.start + sameSentenceContent.indexOf(lineContent);
        let tableNameKeys = this.tableNameKeys.filter((item) => {
          return sameSentenceContent.indexOf(item) !== -1;
        });
        let sameSentenceContentArr = sameSentenceContent
          .slice(0, start)
          .toLowerCase()
          .split(" ");
        sameSentenceContentArr = sameSentenceContentArr.filter(
          (element) => element.trim() !== "" && element.trim() !== " "
        );
        let lastStr = sameSentenceContentArr[sameSentenceContentArr.length - 1];
        tableNameKeys.forEach((tableName) => {
          if (!this.hintFieldList[tableName]) {
            let params = {
              accountExtraInfoId: this.accountExtraInfoId,
              databaseName: this.databaseName,
              tableName,
            };
            api.showTableStructure(params).then((res) => {
              if (res.result === 200) {
                this.responseStatement = {
                  date: res.data.startTime,
                  info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
                  millis: res.data.millis,
                };
                this.$set(
                  this.hintFieldList,
                  tableName,
                  res.data.result.map((item) => {
                    return item.column_name;
                  })
                );
              }
            });
          }
        });
        if (
          tableNameKeys.length > 0 &&
          this.databaseId &&
          (this.ch.indexOf(".") !== -1 ||
            lastStr == "where" ||
            lastStr == "and" ||
            lastStr == "set")
        ) {
          let tableName = "";
          if (this.ch.indexOf(".") !== -1) {
            tableName = this.compareStrings(
              sameSentenceContent,
              start,
              tableNameKeys
            );
          } else {
            tableName = this.getPreviousStringByIndex(
              sameSentenceContent,
              lastStr,
              start,
              tableNameKeys
            );
          }
          this.hintFieldList[tableName].forEach((item) => {
            let key = this.ch;
            if (this.ch.indexOf(".") !== -1) {
              key = this.ch.split(".")[1];
            }
            if (
              ((lastStr == "where" || lastStr == "and" || lastStr == "set") &&
                (key == " " || key == "  ")) ||
              (key == "" && this.ch == ".")
            ) {
              this.tips.push(item);
            } else {
              if (
                key != "" &&
                item.substring(0, key.length).toLowerCase() ===
                  key.toLowerCase()
              ) {
                if (
                  item.toLowerCase().indexOf(key.toLowerCase()) !== -1 &&
                  this.tips.indexOf(item) == -1
                ) {
                  this.tips.push(item);
                }
              }
            }
          });
          if (this.tips.length > 0 && /^[a-zA-Z0-9]$/.test(this.ch)) {
            this.tips.push(this.ch);
          }
        } else {
          this.keys.forEach((item) => {
            if (
              this.ch != "" &&
              item.substring(0, this.ch.length).toLowerCase() ===
                this.ch.toLowerCase()
            ) {
              if (
                item.toLowerCase().indexOf(this.ch.toLowerCase()) !== -1 &&
                this.tips.indexOf(item) == -1
              ) {
                this.tips.push(item);
              }
            }
          });
          if (this.tips.length > 0 && /^[a-zA-Z0-9]$/.test(this.ch)) {
            this.tips.push(this.ch);
          }
          if (event.keyCode === 32 || this.ch === "") {
            this.tips.splice(0);
          }
        }
      });
      this.editor[this.editableTabsValue].setSize("auto", "100%");
      this.editor[this.editableTabsValue].on("change", (cm) => {
        let sql = this.editor[this.editableTabsValue].getValue();
        this.sqlPanes.forEach((item) => {
          if (item.key === this.editableTabsValue) {
            item.content = sql;
          }
        });
        this.setCookie(this.databaseId);
      });
      return CodeMirror;
    },
    compareStrings(a, start, tableNameKeys) {
      let arr = a.slice(0, start).split(" ");
      arr = arr.filter((item) => {
        return item !== "";
      });
      let b = arr[arr.length - 1];
      let index = arr.indexOf(b);
      let tableName = "";
      tableNameKeys.forEach((item) => {
        if (item == arr[index - 1]) {
          tableName = item;
        }
      });
      return tableName;
    },
    getPreviousStringByIndex(a, b, index, tableNameKeys) {
      const regex = new RegExp(`\\b${b}\\b`, "gi");
      let matches = [];
      let match;

      while ((match = regex.exec(a)) !== null) {
        if (match.index >= index) {
          break;
        }
        matches.push(match);
      }
      if (matches.length > 0) {
        const lastMatch = matches[matches.length - 1];
        let previousString = "";
        if (matches.length <= 1) {
          previousString = a.substring(0, lastMatch.index);
        } else {
          previousString = a.substring(
            matches[matches.length - 2].index,
            lastMatch.index
          );
        }
        let tableName = "";
        tableNameKeys.forEach((item) => {
          if (previousString.includes(item)) {
            tableName = item;
          }
        });
        return tableName;
      }
    },
    showHint(cm, list) {
      //sql提示
      if (!list || list.length == 0) {
        return;
      }
      CodeMirror.registerHelper("hint", "sql", function (editor, options) {
        var cur = cm.getCursor(),
          token = cm.getTokenAt(cur);
        var start = token.start,
          end = token.end;

        var cur_line_txt = cm.getDoc().getLine(cur.line);
        var curr_word = cur_line_txt.substring(start, end);
        if (curr_word.indexOf(".") == 0 || curr_word.indexOf(" ") == 0) {
          //开始联想
          start++;
        }
        return {
          list: list,
          from: CodeMirror.Pos(cur.line, start),
          to: CodeMirror.Pos(cur.line, end),
        };
      });
      CodeMirror.showHint(cm, CodeMirror.hint.sql);
    },
    implementSql() {
      if (this.implementSqlLoading) {
        return;
      } else {
        this.implementSqlLoading = true;
      }
      //选中执行sql查询
      let sql = this.editor[this.editableTabsValue].getSelections();
      if (!sql) {
        sql = this.implementSqlData[this.editableTabsValue];
      }
      if (sql[0] == "") {
        this.$message.warning("请选中SQL");
        this.implementSqlLoading = false;
        return;
      }
      let sqlArr = sql[0].split(" ");
      sqlArr.map((item) => {
        this.data.map((menu) => {
          if (item == menu.name) {
            this.sqlTableStructure = menu.name;
          }
        });
      });
      //替换掉非法'；'以及多余的';'
      sql = sql[0]
        .replace(new RegExp("；", "gm"), ";")
        .replace(new RegExp(";+", "gm"), ";")
        .trim();
      // 导出sql初始化
      this.exportDataForm.sql = sql;
      let tmpSql = sql.toUpperCase();
      let limitSql = this.sqlPanes.filter((item) => {
        return item.key == this.editableTabsValue;
      })[0].limitSql;
      if ("SELECT" === tmpSql.slice(0, 6) && limitSql) {
        //只有select语句才会有LIMIT限制
        if ("COUNT(" != tmpSql.slice(7, 13)) {
          //select count(*)不需要LIMIT限制
          if (tmpSql.indexOf("LIMIT") == -1) {
            /*if (confirm("发现没有加LIMIT 是否自动加LIMIT 10")) {
              sql = sql + " LIMIT 10";
              }*/
            if (tmpSql.indexOf(";") === tmpSql.length - 1) {
              // 末尾是 ; 的去掉
              sql = sql.substring(0, sql.length - 1);
            }
            sql = sql + " " + limitSql + ";";
          }
        }
      }
      let params = {
        databaseName: this.databaseName,
        accountExtraInfoId: this.accountExtraInfoId,
        sql: sql,
      };
      this.implementSqlData[this.editableTabsValue] = sql;
      this.submitChangesShow = false;
      this.sqlCheck(params);
    },
    sqlCheck(params) {
      api
        .check(params)
        .then(async (res) => {
          if (res.result == 200) {
            let sqlArr = res.data;
            let data = { ...params };
            for (let index = 0; index < sqlArr.length; index++) {
              const sql = sqlArr[index];
              data.sql = sql;
              await this.clickExecution(data, false);
            }
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.implementSqlLoading = false;
          }, 300);
        });
    },
    async clickExecution(params, sort) {
      this.cancelToken = this.$axios.CancelToken.source();
      this.loadingSql = params.sql;
      this.loadingSqlTime = 0;
      this.loadingSqlTimer = setInterval(() => {
        this.loadingSqlTime++;
      }, 1000);
      this.loadingTimer = setInterval(() => {
        // 设置定时器300毫秒之内返回不显示过度动画
        this.loadingAsyncExecutionShow = true;
      }, 300);
      setTimeout(() => {
        this.implementSqlLoading = false;
      }, 300);
      if (!sort && !this.reQuerySwitch) {
        this.queryData[this.editableTabsValue] = [];
        this.queryColumns[this.editableTabsValue] = [];
      }
      this.$forceUpdate();
      await api.sqlExecute(params).then((res) => {
        if (res.result == 200) {
          this.uuid = res.data;
          const { token } = this.cancelToken;
          let timeout = setTimeout(() => {
            this.asyncExecutionShow = true;
          }, 2000);
          this.fetchAsyncJobResult(sort, token, timeout);
        }
      });
    },
    async fetchAsyncJobResult(sort, token) {
      await api
        .fetchAsyncJobResult({ uuid: this.uuid }, token)
        .then((res) => {
          if (res.data.running) {
            this.fetchAsyncJobResult(sort, token);
          } else {
            this.clearLoadingTimer();

            if (res.result == 200) {
              if (res.data.succeed) {
                let result = JSON.parse(res.data.result);
                this.responseStatement = {
                  date: "-- " + result.startTime,
                  info: result.sql ? result.sql.split("\n") : result.sql,
                  millis: "耗时：" + result.millis + " ms",
                };
                if (result.mode === "Select") {
                  this.sqlTableName[this.editableTabsValue] = result.tableName;
                  let list = result.data;
                  let columns = result.columns;
                  list.map((item, i) => {
                    item.lineList = [];
                    columns.map((menu, index) => {
                      let obj = {};
                      obj.key = index + 1;
                      obj.oneLine = menu.columnName;
                      obj.typeName =
                        menu.typeName + "(" + menu.columnSize + ")";
                      for (let key in item) {
                        if (key == menu.columnName) {
                          obj.twoLine = item[key];
                        }
                      }
                      item.lineList.push(obj);
                    });
                  });
                  columns.forEach((n, i) => {
                    n.title = n.columnName;
                    n.ellipsis = true;
                    n.dataIndex = n.columnName;
                    n.scopedSlots = { customRender: n.columnName };
                  });
                  this.queryTableKey = "SQL_id_";
                  list.forEach((n, i) => {
                    n[this.queryTableKey] = i + 1;
                    n.edit_text = {};
                    n.delete_row = false;
                    n.add_row = false;
                    n.col = "";
                    for (let j in n) {
                      // 转化值为[null]、0的单元格
                      if (n[j] === "[null]") {
                        n[j] = "NULL";
                      }
                      if (typeof n[j] === "number") {
                        n[j] = n[j].toString();
                      }
                    }
                  });
                  this.queryData[this.editableTabsValue] = list;
                  this.originalSqlData[this.editableTabsValue] =
                    this.$common.deepClone(list);
                  columns.forEach((n, i) => {
                    n.sorter = true;
                    n.width = this.flexColumnWidth(n.columnName); //表格列宽自适应计算
                  });
                  if (!sort || this.reQuerySwitch) {
                    this.queryColumns[this.editableTabsValue] = columns;
                    if (this.reQuerySwitch) {
                      this.reQuerySwitch = false;
                    }
                  }

                  this.tableIndex[this.editableTabsValue] = 0;
                  if (list.length > 0) {
                    this.lineTableData[this.editableTabsValue] =
                      this.queryData[this.editableTabsValue][0].lineList;
                  }
                } else {
                  this.$message.success("操作成功");
                }
              } else {
                this.queryData[this.editableTabsValue] = [];
                this.$message.error(res.data.result);
              }
            } else if (res.result == 201) {
              this.queryData[this.editableTabsValue] = [];
              this.$message.warning(res.data.message);
            } else {
              this.queryData[this.editableTabsValue] = [];
              this.$message.warning(res);
            }
            this.$forceUpdate();
          }
        })
        .catch((err) => {
          this.clearLoadingTimer();

          if (err.response.status === 403) {
            this.$confirm({
              title: err.response.data.message,
              content: "是否审核sql：" + params.sql,
              onOk: () => {
                this.sqlAuditRemark = "";
                this.isImplement = false;
                this.sqlAuditExecuteTime = null;
                this.sqlAuditContent = params.sql;
                this.sqlAuditShow = true;
              },
              onCancel() {},
            });
          }
        });
    },
    asyncExecution() {
      api.change2BackgroudRun({ uuid: this.uuid }).then((res) => {
        if (res.result == 200) {
          this.$message.success("已转为异步执行");
          this.cancelToken.cancel();
          this.asyncBadgeCount = 1;
          this.clearLoadingTimer();
        }
      });
    },
    cancelExecution() {
      let data = {
        accountExtraInfoId: this.accountExtraInfoId,
        uuid: this.uuid,
      };
      api.tryKillProcess(data).then((res) => {
        if (res.result == 200) {
          this.cancelToken.cancel();
          this.clearLoadingTimer();
          this.$message.success("已取消执行");
        }
      });
    },
    cancelLoading() {
      this.cancelToken.cancel();
      this.clearLoadingTimer();
      this.$message.success("已取消执行");
    },
    clearLoadingTimer() {
      if (this.loadingTimer) {
        clearInterval(this.loadingTimer);
        this.loadingTimer = null;
      }

      if (this.loadingSqlTimer) {
        clearInterval(this.loadingSqlTimer);
        this.loadingSqlTimer = null;
      }
      this.loadingShow = false;
      this.loadingAsyncExecutionShow = false;
      this.asyncExecutionShow = false;
    },
    listAsyncSqlJob() {
      this.asyncSqlJobLoading = true;
      api.listAsyncSqlJob().then((res) => {
        if (res.result == 200) {
          this.asyncSqlJobList = res.data;
          this.listAsyncSqlJobShow = true;
          this.asyncBadgeCount = 0;
          this.asyncSqlJobLoading = false;
        }
      });
    },
    viewSql(record) {
      this.viewSqlShow = true;
      this.viewSqlText = record.sql;
    },
    viewResult(record) {
      if (record.succeed) {
        this.listAsyncSqlJobShow = false;
        let result = JSON.parse(record.result);
        this.responseStatement = {
          date: "-- " + result.startTime,
          info: result.sql ? result.sql.split("\n") : result.sql,
          millis: "耗时：" + result.millis + " ms",
        };
        if (result.mode === "Select") {
          this.sqlTableName[this.editableTabsValue] = result.tableName;
          let list = result.data;
          let columns = result.columns;
          list.map((item, i) => {
            item.lineList = [];
            columns.map((menu, index) => {
              let obj = {};
              obj.key = index + 1;
              obj.oneLine = menu.columnName;
              obj.typeName = menu.typeName + "(" + menu.columnSize + ")";
              for (let key in item) {
                if (key == menu.columnName) {
                  obj.twoLine = item[key];
                }
              }
              item.lineList.push(obj);
            });
          });
          columns.forEach((n, i) => {
            n.title = n.columnName;
            n.ellipsis = true;
            n.dataIndex = n.columnName;
            n.scopedSlots = { customRender: n.columnName };
          });
          this.queryTableKey = "SQL_id_";
          list.forEach((n, i) => {
            n[this.queryTableKey] = i + 1;
            n.edit_text = {};
            n.delete_row = false;
            n.add_row = false;
            n.col = "";
            for (let j in n) {
              // 转化值为[null]、0的单元格
              if (n[j] === "[null]") {
                n[j] = "NULL";
              }
              if (typeof n[j] === "number") {
                n[j] = n[j].toString();
              }
            }
          });
          this.queryData[this.editableTabsValue] = list;
          this.originalSqlData[this.editableTabsValue] =
            this.$common.deepClone(list);
          columns.forEach((n, i) => {
            n.sorter = true;
            n.width = this.flexColumnWidth(n.columnName); //表格列宽自适应计算
          });
          this.queryColumns[this.editableTabsValue] = columns;

          this.tableIndex[this.editableTabsValue] = 0;
          if (list.length > 0) {
            this.lineTableData[this.editableTabsValue] =
              this.queryData[this.editableTabsValue][0].lineList;
          }
        } else {
          this.$message.success("操作成功");
        }
      } else {
        this.queryData[this.editableTabsValue] = [];
        this.$message.error(record.result);
      }
      this.$forceUpdate();
    },
    // 添加sql审核定时执行
    onChangeTiming(e) {
      this.isImplement = e.target.checked;
    },
    // 提交sql审核
    sqlAudit_submit() {
      let executeTime = "";
      if (!this.isImplement) {
        executeTime = this.$common.transformTime(new Date());
      }
      if (this.isImplement) {
        if (this.sqlAuditExecuteTime) {
          executeTime = this.$common.transformTime(this.sqlAuditExecuteTime);
        }
      }
      let data = {
        instanceId: this.instanceId,
        databaseName: this.databaseName,
        content: this.sqlAuditContent,
        executeTime: executeTime,
        remark: this.sqlAuditRemark,
      };
      api.addSqlAudit(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("成功提交审核");
          this.sqlAuditShow = false;
        }
      });
    },
    formatClick() {
      //格式化
      /*获取文本编辑器内容*/
      let sqlContent = "";
      sqlContent = this.editor[this.editableTabsValue].getValue();
      /*将sql内容进行格式后放入编辑器中*/
      this.editor[this.editableTabsValue].setValue(
        sqlFormatter.format(sqlContent)
      );
    },
    refreshContentTable() {
      let param = {
        accountExtraInfoId: this.accountExtraInfoId,
        databaseName: this.databaseName,
        tableName: this.editableTabsValue.split("_").slice(1, -1).join("_"),
        offset: this.offsetMap[this.editableTabsValue],
        limit: this.offsetMap[this.editableTabsValue] + 300,
      };
      let orderStorage = localStorage.getItem(
        this.accountExtraInfoId + "_" + this.editableTabsValue + "Order"
      );
      if (orderStorage) {
        let order = JSON.parse(orderStorage);
        param.sortColumn = order.sortColumn;
        param.asc = order.asc;
      }
      this.inquireTableContent(param, true);
    },
    inquireTableContent(data, refresh) {
      //执行sql查询
      this.$forceUpdate();
      this.cancelToken = this.$axios.CancelToken.source();
      const { token } = this.cancelToken;
      this.loadingSqlTime = 0;
      this.loadingSqlTimer = setInterval(() => {
        this.loadingSqlTime++;
      }, 1000);
      this.loadingTimer = setInterval(() => {
        // 设置定时器300毫秒之内返回不显示过度动画
        this.loadingShow = true;
      }, 300);
      api
        .showTableContent(data, token)
        .then((res) => {
          this.clearLoadingTimer();

          if (res.result == 200) {
            this.responseStatement = {
              date: res.data.startTime,
              info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
              millis: res.data.millis,
            };
            let columns = res.data.columns;
            this.tableKey = "content_id_";
            this.sqlTableName[this.editableTabsValue] = data.tableName;
            let list = res.data.result;
            list.map((item, i) => {
              item.lineList = [];
              columns.map((menu, index) => {
                let obj = {};
                obj.key = index + 1;
                obj.oneLine = menu.columnName;
                for (let key in item) {
                  if (key == menu.columnName) {
                    obj.twoLine = item[key];
                  }
                }
                item.lineList.push(obj);
              });

              item[this.tableKey] = i + 1;
              item.edit_text = {};
              item.delete_row = false;
              item.add_row = false;
              item.col = "";
              for (let j in item) {
                // 转化值为[null]、0的单元格
                if (item[j] === "[null]") {
                  item[j] = "NULL";
                }

                if (typeof item[j] === "number") {
                  item[j] = item[j].toString();
                }
              }
            });
            this.contentTableData[this.editableTabsValue] = list;
            this.originalSqlData[this.editableTabsValue] =
              this.$common.deepClone(list);
            if (
              !this.contentColumnsList[this.editableTabsValue] ||
              this.reQuerySwitch
            ) {
              columns.forEach((n) => {
                n.title = n.columnName;
                n.ellipsis = true;
                n.dataIndex = n.columnName;
                n.scopedSlots = { customRender: n.columnName };
                n.sorter = true;
                n.width = this.flexColumnWidth(null, null, n.columnName); //表格列宽自适应计算
              });
              this.contentColumnsList[this.editableTabsValue] = columns;
              if (this.reQuerySwitch) {
                this.reQuerySwitch = false;
              }
            } else {
              this.getWhetherItHasChanged(this.contentTableData);
            }
            this.$set(this.offsetMap, this.editableTabsValue, res.data.offset);
            this.$set(this.limitMap, this.editableTabsValue, res.data.limit);
            this.turnPagePreviousDisabled = !res.data.prevEnabled;
            this.turnPageNextDisabled = !res.data.nextEnabled;
            if (refresh) {
              this.$message.success("刷新成功");
            }
          }
        })
        .catch((err) => {
          clearInterval(timer);
          this.loadingShow = false;
        });
    },
    inquireTableStructure(data) {
      this.$forceUpdate();
      this.cancelToken = this.$axios.CancelToken.source();
      const { token } = this.cancelToken;
      this.loadingSqlTime = 0;
      this.loadingSqlTimer = setInterval(() => {
        this.loadingSqlTime++;
      }, 1000);
      this.loadingTimer = setInterval(() => {
        // 设置定时器300毫秒之内返回不显示过度动画
        this.loadingShow = true;
      }, 300);
      api
        .showTableStructure(data, token)
        .then((res) => {
          this.clearLoadingTimer();

          if (res.result === 200) {
            this.responseStatement = {
              date: res.data.startTime,
              info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
              millis: res.data.millis,
            };

            this.sqlTableKey = "structure_id_";
            this.sqlTableName[this.editableTabsValue] = data.tableName;
            let list = res.data.result;
            let columns = [];

            if (list.length > 0) {
              for (const key in list[0]) {
                columns.push({ columnName: key });
              }

              list.forEach((n, i) => {
                n[this.sqlTableKey] = i + 1;
                n.edit_text = {};
                n.delete_row = false;
                n.add_row = false;
                n.col = "";
                for (let j in n) {
                  // 转化值为[null]、0的单元格
                  if (n[j] === "[null]") {
                    n[j] = "NULL";
                  }

                  if (typeof n[j] === "number") {
                    n[j] = n[j].toString();
                  }
                }
              });
            }
            this.sqlTableList[this.editableTabsValue] = list;
            this.originalSqlData[this.editableTabsValue] =
              this.$common.deepClone(list);
            if (
              !this.sqlColumnsList[this.editableTabsValue] ||
              this.reQuerySwitch
            ) {
              columns.forEach((n, i) => {
                n.title = n.columnName;
                n.ellipsis = true;
                n.dataIndex = n.columnName;
                n.scopedSlots = { customRender: n.columnName };
                n.width = this.flexColumnWidth(null, n.columnName); //表格列宽自适应计算
              });
              this.sqlColumnsList[this.editableTabsValue] = columns;
              if (this.reQuerySwitch) {
                this.reQuerySwitch = false;
              }
            } else {
              this.getWhetherItHasChanged(this.sqlTableList);
            }
            this.sqlColumnOptionsMap[this.editableTabsValue] =
              res.data.columnOptionsMap;
            if (res.data.defaultValueMap) {
              this.defaultValueMap[this.editableTabsValue] =
                res.data.defaultValueMap;
            }
          }
          this.inquireIndexStructure(data);
          this.getCreateTableSql(data);
        })
        .catch((err) => {
          this.loadingShow = false;
        });
    },
    // 查询表结构 --INDEX
    inquireIndexStructure(data) {
      this.$forceUpdate();
      this.cancelToken = this.$axios.CancelToken.source();
      const { token } = this.cancelToken;
      this.loadingSqlTime = 0;
      this.loadingSqlTimer = setInterval(() => {
        this.loadingSqlTime++;
      }, 1000);
      this.loadingTimer = setInterval(() => {
        // 设置定时器300毫秒之内返回不显示过度动画
        this.loadingShow = true;
      }, 300);
      api
        .showIndex(data, token)
        .then((res) => {
          this.clearLoadingTimer();

          if (res.result === 200) {
            this.responseStatement = {
              date: res.data.startTime,
              info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
              millis: res.data.millis,
            };

            this.implementTableKey = "structure_id_";
            let list = res.data.result;
            let columns = [];

            if (list.length > 0) {
              for (const key in list[0]) {
                columns.push({ columnName: key });
              }
              list.forEach((n, i) => {
                n[this.implementTableKey] = i + 1;
                n.edit_text = {};
                n.delete_row = false;
                n.add_row = false;
                n.col = "";
                for (let j in n) {
                  // 转化值为[null]的、0单元格
                  if (n[j] === "[null]") {
                    n[j] = "NULL";
                  }
                  if (typeof n[j] === "number") {
                    n[j] = n[j].toString();
                  }
                }
              });
            }
            this.implementList[this.editableTabsValue] = list;
            this.originalImplementData[this.editableTabsValue] =
              this.$common.deepClone(list);
            columns.forEach((n, i) => {
              n.title = n.columnName;
              n.ellipsis = true;
              n.dataIndex = n.columnName;
              n.scopedSlots = { customRender: n.columnName };
              n.width = this.flexColumnWidth(null, null, null, n.columnName); //表格列宽自适应计算
            });
            this.implementColumnList[this.editableTabsValue] = columns;
            this.implementColumnOptionsMap[this.editableTabsValue] =
              res.data.columnOptionsMap;
            if (res.data.defaultValueMap) {
              this.defaultValueIndexMap[this.editableTabsValue] =
                res.data.defaultValueMap;
            }
          }
        })
        .catch((err) => {
          clearInterval(timer);
          this.loadingShow = false;
        });
    },
    getCreateTableSql(data) {
      api.showCreateTableSql(data).then((res) => {
        if (res.result === 200) {
          this.responseStatement = {
            date: res.data.startTime,
            info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
            millis: res.data.millis,
          };
          this.createTableSql = res.data.result;
        }
      });
    },
    inquireTableInfo(params) {
      //查询表信息
      api.tableInfo(params).then((res) => {
        this.infoTextarea = res.data.createTable;
        this.inputEncoding = res.data.engine;
        this.inputCollation = res.data.collation;
        this.autoIncrement = res.data.autoIncrement;
        this.rowFormat = res.data.rowFormat;
        this.rows = res.data.rows;
        this.dataSize = res.data.size;
        if (res.data.updated != "[null]") {
          this.updatedAt = this.$common.transformTime(res.data.updated);
        } else {
          this.updatedAt = "--";
        }
        this.createdAt = this.$common.transformTime(res.data.created);
      });
    },
    keyboard() {
      //点击键盘快捷方式
      this.keyboardShow = true;
    },
    keyboardClose() {
      //关闭键盘快捷方式
      this.inputKey = "";
      this.keysWord = "";
    },
    modifyKey(val) {
      //修改快捷键
      this.modifyKeyShow = true;
      this.bindingKey = val.keyBinding;
    },
    keyText(e) {
      //监听快捷键输入
      let c = "";
      if (e.keyCode == 16) {
        c = "Shift";
        e.returnValue = false;
      }
      if (e.keyCode == 17) {
        c = "Ctrl";
        e.returnValue = false; //禁止键盘默认事件
      }
      if (e.keyCode == 18) {
        c = "Alt";
        e.returnValue = false;
      }
      if (e.keyCode == 9) {
        c = "Tab";
        e.returnValue = false;
      }
      if (e.keyCode == 37) {
        c = "Left";
        e.returnValue = false;
      }
      if (e.keyCode == 38) {
        c = "Up";
        e.returnValue = false;
      }
      if (e.keyCode == 39) {
        c = "Right";
        e.returnValue = false;
      }
      if (e.keyCode == 40) {
        c = "Down";
        e.returnValue = false;
      }
      if (e.keyCode == 32) {
        c = "Space";
        e.returnValue = false;
      }
      if (this.inputKey) {
        this.inputKey += " " + c;
      } else {
        this.inputKey = c;
      }
      if (e.keyCode == 8) {
        e.returnValue = false;
        let arr = [];
        let str = this.inputKey;
        str = str.split(" ");
        str.map((i) => {
          if (i) {
            arr.push(i);
          }
        });
        str = arr.slice(0, str.length - 2);
        this.inputKey = str.join(" ");
      }
      let extra;
      if (e.keyCode == 13 && this.keysWord != "") {
        this.keyboardList.map((item) => {
          if (this.bindingKey == item.keyBinding) {
            extra = item.extra;
            item.keyBinding = this.keysWord;
          }
        });
        let a = {};
        a[this.keysWord] = extra;
        let b = {};
        b[this.bindingKey] = (cm) => {};
        this.editor[this.editableTabsValue].addKeyMap(a);
        this.editor[this.editableTabsValue].addKeyMap(b);
        this.modifyKeyShow = false;
        sessionStorage.setItem("keyWord", JSON.stringify(this.keyboardList));
        let editorText = this.editor[this.editableTabsValue].getValue();
        sessionStorage.setItem("editorText", JSON.stringify(editorText));
      }
    },
    handleMySql() {
      //点击我的SQL
      this.mySqlShow = true;
      this.mysqlpageNo = 1;
      let params = {
        pageNo: 1,
        pageSize: 10,
        type: "SQL",
      };
      this.inquireMySql(params);
    },
    // 点击我的SQL修改
    mySqlModify(val) {
      this.addSqlTitle = "修改SQl";
      this.addSqlShow = true;
      this.addSql.sqlName = val.name;
      this.addSql.sql = val.content;
      this.addSql.remark = val.remark;
      this.sqlId = val.id;
    },
    mySqlUse(val) {
      //我的SQL使用
      let oldSql = this.editor[this.editableTabsValue].getValue();
      let sql = "";
      if (oldSql) {
        sql = oldSql + "\n" + val.content;
      } else {
        sql = val.content;
      }
      this.editor[this.editableTabsValue].getDoc().setValue(sql);
      this.mySqlCencel();
    },
    mySqlDelete(val) {
      //我的SQL删除
      let data = {
        id: val.id,
      };
      this.$confirm({
        title: "确认删除?",
        content: `此操作将删除【${val.name}】的SQL?, 是否继续`,
        onOk: () => {
          api.memoDeleteMemo(data).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.inquireMySql({
                pageNo: this.mysqlpageNo,
                pageSize: 10,
                type: "SQL",
              });
            }
          });
        },
        onCancel() {},
      });
    },
    addSqlSubmit() {
      //保存新增sql
      let data = {
        name: this.addSql.sqlName,
        content: this.addSql.sql,
        remark: this.addSql.remark,
      };
      if (data.name && data.content) {
        this.loading = true;
        if (this.addSqlTitle === "新增SQl") {
          data.type = "SQL";
          api
            .memoAddMemo(data)
            .then((res) => {
              this.loading = false;
              if (res.result == 200) {
                this.addSqlCencel();
                this.inquireMySql({
                  pageNo: this.mysqlpageNo,
                  pageSize: 10,
                  type: "SQL",
                });
                this.$message.success("新增成功");
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
        if (this.addSqlTitle === "修改SQl") {
          data.id = this.sqlId;
          api
            .memoUpdateMemo(data)
            .then((res) => {
              this.loading = false;
              if (res.result == 200) {
                this.addSqlCencel();
                this.inquireMySql({
                  pageNo: this.mysqlpageNo,
                  pageSize: 10,
                  type: "SQL",
                });
                this.$message.success("修改成功");
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      } else {
        this.$message.warning("信息不完整");
      }
    },
    addSqlCencel() {
      //取消新增sql
      this.addSqlShow = false;
    },
    mySqlClose() {
      //关闭新增SQL
      this.addSql = {
        sqlName: "",
        sql: "",
        remark: "",
      };
    },
    mySqlSee(val) {
      //我的SQL查看
      this.seeSqlShow = true;
      this.seeSql = val.content;
    },
    seeSqlClose() {
      //关闭我的SQL查看
      this.seeSql = "";
    },
    mySqlAdd() {
      //我的SQL新增
      this.addSqlTitle = "新增SQl";
      this.addSqlShow = true;
    },
    mySqlCencel() {
      //取消我的SQL
      this.mySqlShow = false;
    },
    inquireMySql(data) {
      //查询我的sql
      api.memoList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.forEach((item, i) => {
            item.key = i + 1;
          });
          this.mySqlList = list;
          this.mySqlPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.mySqlChangePageItem(current),
          };
        }
      });
    },
    mySqlChangePageItem(current) {
      //我的sql分页
      this.mysqlpageNo = current;
      let data = {
        pageNo: current,
        pageSize: 10,
        type: "SQL",
      };
      this.inquireMySql(data);
    },
    showHelp() {
      let data = {
        type: this.databaseType,
      };
      api.showHelp(data).then((res) => {
        if (res.result == 200) {
          this.showHelpMap = res.data.result;
          this.showHelpVisible = true;
        }
      });
    },
    inquireList(firstLoad, reload) {
      api.listUserInstance().then((res) => {
        if (res.result == 200) {
          this.instanceList = res.data;
          if (firstLoad) {
            if (
              localStorage.getItem("instanceId") &&
              localStorage.getItem("instanceId") !== "null"
            ) {
              this.instanceId = localStorage.getItem("instanceId") * 1;
              this.instanceInit();
            }
          }
          if (reload) {
            this.$message.success("刷新成功");
          }
        }
      });
    },
    instanceChange() {
      localStorage.setItem("instanceId", this.instanceId);
      this.instanceInit();
    },
    instanceInit() {
      this.databaseId = undefined;
      this.databaseTabList.splice(0);
      this.editWindowShow = false;

      if (
        Array.isArray(
          JSON.parse(localStorage.getItem("databaseTabList" + this.instanceId))
        )
      ) {
        this.databaseTabList = JSON.parse(
          localStorage.getItem("databaseTabList" + this.instanceId)
        );
      }
      if (this.databaseTabList.length > 0) {
        this.databaseTabIndex =
          localStorage.getItem("databaseTabIndex" + this.instanceId) * 1;
        this.databaseChange(this.databaseTabList[this.databaseTabIndex]);
      } else {
        this.tableTitle = "";
        this.tabCreated = {}; // 清空创建的editor
        this.editableTabsValue = "query_0";
        this.tableList.splice(0);
        this.data.splice(0);
        this.tableNameKeys.splice(0);
        this.setHeaderTitle();
      }
      this.getColorAndTag();
    },
    databaseConfigChange() {
      this.addDatabaseId = undefined;
      this.getDataBaseList();
    },
    getDataAccountList() {
      let data = {
        instanceId: this.instanceId,
      };
      api.listUserAccount(data).then((res) => {
        if (res.result == 200) {
          this.accountList = res.data;
          if (this.accountList.length > 0) {
            this.addAccountExtraInfoId = this.accountList[0].accountExtraInfoId;
          }
          this.addDatabaseId = undefined;
          this.addDatabaseShow = true;
          this.getDataBaseList();
        }
      });
    },
    getDataBaseList(databaseName) {
      let data = {
        accountExtraInfoId: this.addAccountExtraInfoId,
        databaseName,
      };
      api.listUserDatabase(data).then((res) => {
        if (res.result == 200) {
          this.userDatabaseList = res.data;
        }
      });
    },
    refreshDatabaseConfig() {
      let data = {
        instanceId: this.instanceId,
      };
      api.refreshDatabaseConfig(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("刷新成功");
          this.getDataBaseList();
        }
      });
    },
    inquireTable(refresh) {
      let data = {
        databaseName: this.databaseName,
        accountExtraInfoId: this.accountExtraInfoId,
      };

      api.showTables(data).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.responseStatement = {
              date: res.data.startTime,
              info: res.data.sql ? res.data.sql.split("\n") : res.data.sql,
              millis: res.data.millis,
            };
            this.tableNameKeys.splice(0);
            this.data.splice(0);
            res.data.result.map((item) => {
              this.tableNameKeys.push(item);
              this.data.push({
                title: item,
                key: item,
                tableName: item,
                newTableName: item,
                drop: false,
                truncate: false,
              });
            });
            this.tableList = this.$common.deepClone(this.data);
            this.tableList.forEach((item) => {
              this.$set(item, "scopedSlots", { title: "custom" });
            });
            if (this.searchValue) {
              this.onChangeTree();
            }
            // codeMirror提示字符
            this.keys = [...this.sqlKeys, ...this.tableNameKeys];
          }
          if (refresh) {
            this.$message.success("刷新成功");
            this.onChangeTree();
          } else {
            this.$nextTick(() => {
              this.sqlCreated(this.databaseId);
            });
          }
        }
      });
    },
    // 获取树状图关键字
    getParentKey(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    // 模糊搜索
    onChangeTree() {
      this.tableList.forEach((item) => {
        this.data.forEach((newItem) => {
          if (item.tableName == newItem.tableName) {
            this.$set(newItem, "truncate", item.truncate);
            this.$set(newItem, "drop", item.drop);
            this.$set(newItem, "newTableName", item.newTableName);
          }
        });
      });

      const filterNode = this.data.map((item) => {
        if (!this.searchValue) return true;
        if (item.title.indexOf(this.searchValue) > -1) {
          return true;
        }
        return false;
      });
      this.tableList.splice(0);
      for (let i in filterNode) {
        for (let j in this.data) {
          if (i === j && filterNode[i] === true) {
            this.tableList.push(this.data[j]);
          }
        }
      }
    },
    sqlHandleClick(tab) {
      //选择----tab页
      this.editableTabsValue = tab;
      this.submitChangesShow = false;
      let key = tab.substring(0, tab.indexOf("_"));
      this.$nextTick(() => {
        for (let i in this.sqlPanes) {
          if (this.sqlPanes[i].key == tab && key === "query") {
            // sql窗口
            this.createdEditor(i);
            if (this.tabCreated[this.editableTabsValue]) {
              // 如果以创建就retrun
              if (this.queryColumns[this.editableTabsValue]) {
                this.queryColumns[this.editableTabsValue].forEach((n, i) => {
                  n.width = this.flexColumnWidth(n.columnName); //表格列宽自适应计算
                });
              }
            }
          }
          if (this.sqlPanes[i].key == tab && key === "content") {
            // 内容
            if (this.tabCreated[this.editableTabsValue]) {
              // 如果以创建就retrun
              this.contentColumnsList[this.editableTabsValue].forEach(
                (n, i) => {
                  n.width = this.flexColumnWidth(null, null, n.columnName); //表格列宽自适应计算
                }
              );
              this.getWhetherItHasChanged(this.contentTableData);
            } else {
              let param = {
                accountExtraInfoId: this.accountExtraInfoId,
                databaseName: this.databaseName,
                tableName: tab.split("_").slice(1, -1).join("_"),
                offset: 0,
                limit: 300,
              };
              this.inquireTableContent(param);
            }
          }
          if (this.sqlPanes[i].key == tab && key === "structure") {
            // 表结构
            if (this.tabCreated[this.editableTabsValue]) {
              // 如果以创建就retrun
              this.sqlColumnsList[this.editableTabsValue].forEach((n, i) => {
                n.width = this.flexColumnWidth(null, n.columnName); //表格列宽自适应计算
              });
              this.getWhetherItHasChanged(this.sqlTableList);
            } else {
              let params = {
                accountExtraInfoId: this.accountExtraInfoId,
                databaseName: this.databaseName,
                tableName: this.sqlPanes[i].content,
              };
              this.inquireTableStructure(params);
            }
          }
        }
        if (!this.tabCreated[tab]) {
          this.sqlPanes.forEach((item) => {
            if (item.key === tab) {
              let key = item.key.substring(0, item.key.indexOf("_"));
              if (key === "content") {
                let rowData = {
                  databaseName: this.databaseName,
                  accountExtraInfoId: this.accountExtraInfoId,
                  tableName: item.title.split("内容: ")[1],
                };
                api.getTableRows(rowData).then((res) => {
                  if (res.result == 200) {
                    this.responseStatement = {
                      date: res.data.startTime,
                      info: res.data.sql
                        ? res.data.sql.split("\n")
                        : res.data.sql,
                      millis: res.data.millis,
                    };
                    this.totalMap[tab] = res.data.result;
                    this.$forceUpdate();
                  }
                });
              }
            }
          });
        }
        this.tabCreated[tab] = tab; // 记录已创建的窗口
      });

      if (tab == "information") {
        //表信息
        let params = {
          databaseId: this.databaseId,
          tableName: this.sqlTableStructure,
        };
        if (params.databaseId && params.tableName) {
          this.inquireTableInfo(params);
        }
      }
    },
    //计算左侧栏大小
    dragEagle(e) {
      var targetDiv = document.getElementsByClassName("tableMenu")[0];
      //得到点击时该容器的宽高：
      var targetDivWidth = targetDiv.offsetWidth;
      var startX = e.clientX;

      document.onmousemove = (e) => {
        e.preventDefault();
        //得到鼠标拖动的宽高距离：取绝对值
        var distX = Math.abs(e.clientX - startX);
        if (e.clientX > startX) {
          targetDiv.style.width = targetDivWidth + distX + "px";
        } else if (e.clientX < startX) {
          targetDiv.style.width = targetDivWidth - distX + "px";
        }
        //设置最小范围：不能无限制缩放，影响体验
        if (parseInt(targetDiv.style.width) <= 60) {
          targetDiv.style.width = 60 + "px";
        }
      };
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    },
    dragEagleText(e) {
      //计算标签页大小--查询
      let resultDiv = {},
        textDiv = {};
      this.sqlPanes.forEach((item) => {
        if (this.editableTabsValue === item.key) {
          resultDiv[item.key] = document.getElementById(
            "resultTableId_" + item.key
          );
          textDiv[item.key] = document.getElementById("textareaId_" + item.key);
        }
      });
      //得到点击时该容器的宽高：
      var textDivHeight = textDiv[this.editableTabsValue].offsetHeight;
      var startClientY = e.clientY;
      var newsDivHeight = resultDiv[this.editableTabsValue].offsetHeight;
      document.onmousemove = (e) => {
        e.preventDefault();
        //得到鼠标拖动的宽高距离：取绝对值
        var diffY = e.clientY - startClientY;
        textDiv[this.editableTabsValue].style.height =
          textDivHeight + diffY + "px";
        resultDiv[this.editableTabsValue].style.height =
          newsDivHeight - diffY + "px";
        this.$forceUpdate();
      };
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    },
    flexColumnWidth(val, tableName, contentName, implementName) {
      //表格列宽自适应计算
      let flexWidth = 0; // 计算宽度
      let name = ""; // 计算的内容
      let index = ""; // 记录的表头名称
      let tableContent = []; // 表格数据
      if (tableName) {
        name = tableName;
        index = tableName;
        tableContent = this.sqlTableList[this.editableTabsValue];
      }
      if (contentName) {
        name = contentName;
        index = contentName;
        tableContent = this.contentTableData[this.editableTabsValue];
      }
      if (implementName) {
        name = implementName;
        index = implementName;
        tableContent = this.implementList[this.editableTabsValue];
      }
      if (val) {
        name = val;
        index = val;
        tableContent = this.queryData[this.editableTabsValue];
      }
      tableContent.forEach((item) => {
        //判断表头与内容长度
        for (let i in item) {
          if (i == index) {
            //判断表头是否一致
            if (!item[i]) {
              item[i] = "";
            }
            if (item[i].length > name.length) {
              name = item[i];
            }
          }
        }
      });
      for (const char of name) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配10个单位宽度
          flexWidth += 12;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配15个单位宽度
          flexWidth += 15;
        } else {
          // 其他种类字符，为字符分配8个单位宽度
          flexWidth += 8;
        }
      }
      if (flexWidth < 50) {
        // 设置最小宽度
        flexWidth = 50;
      }
      if (flexWidth > 350) {
        // 设置最大宽度
        flexWidth = 450;
      }
      return flexWidth + 20;
    },
    // 底边栏操作
    turnPage(type) {
      //上一页or下一页
      let offset = 0;
      if (type === "per") {
        offset = this.offsetMap[this.editableTabsValue] - 300;
      }
      if (type === "next") {
        offset = this.offsetMap[this.editableTabsValue] + 300;
      }
      let param = {
        accountExtraInfoId: this.accountExtraInfoId,
        databaseName: this.databaseName,
        tableName: this.editableTabsValue.split("_").slice(1, -1).join("_"),
        offset,
        limit: offset + 300,
      };
      this.inquireTableContent(param);
      this.setCookie(this.databaseId);
    },
    lineDetails() {
      //点击单行详情
      this.lineDetailsShow = true;
      this.lastArticleDis = true;
      this.nextItemDis = true;
      if (
        this.queryData[this.editableTabsValue] &&
        this.queryData[this.editableTabsValue].length > 0
      ) {
        let length = this.queryData[this.editableTabsValue].length - 1;
        this.sub = this.tableIndex[this.editableTabsValue];
        if (this.sub == 0) {
          this.nextItemDis = false;
        }
        if (this.sub == length) {
          this.lastArticleDis = false;
        }
        if (this.sub > 0 && this.sub < length) {
          this.lastArticleDis = false;
          this.nextItemDis = false;
        }
      }
    },
    lineDetailsClose() {
      // 关闭单行详情
      // this.lineTableData = {};
    },
    itemButton(index) {
      // 点击上or下一行
      let length = this.queryData[this.editableTabsValue].length - 1;
      if (
        this.queryData[this.editableTabsValue] &&
        this.queryData[this.editableTabsValue].length > 0
      ) {
        if (index == 1) {
          this.lastArticleDis = false;
          this.nextItemDis = false;
          if (this.sub == 0) {
            return;
          }
          this.sub--;
          if (this.sub == 0) {
            this.lastArticleDis = true;
          }
        }
        if (index == 2) {
          this.lastArticleDis = false;
          this.nextItemDis = false;
          if (this.sub == length) {
            return;
          }
          this.sub++;
          if (this.sub == length) {
            this.nextItemDis = true;
          }
        }
      }
      this.tableIndex[this.editableTabsValue] = this.sub;
      this.lineTableData[this.editableTabsValue] =
        this.queryData[this.editableTabsValue][this.sub].lineList;
      this.$forceUpdate();
    },
    exportData() {
      this.exportDataShow = true;
      this.exportDataForm.format = "EXCEL";
    },
    importData() {
      this.importDataShow = true;
    },
    exportData_submit() {
      let data = {
        fileTypeEnum: this.exportDataForm.format,
        databaseName: this.databaseName,
        accountExtraInfoId: this.accountExtraInfoId,
        sql: this.exportDataForm.sql,
      };
      api.generateFile(data).then((res) => {
        if (res.result === 200) {
          this.exportDataShow = false;
          this.$message.success("导出任务提交成功");
          this.importOrExportCount = 1;
        }
      });
    },
    callback(key) {
      if (key == "1") {
        this.getDataExportLogList(1);
      } else if (key == "2") {
        this.getDataImportLogList(1);
      }
    },
    download(record) {
      location.href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        `/db/dataDump/download?id=${record.id}`;
    },
    // 数据导入
    importOrExportData() {
      this.importOrExportDataShow = true;
      this.active = "1";
      this.importOrExportCount = 0;
      this.callback(this.active);
    },
    updateImportDatabaseSource(val, submitOverDumpOperationImport) {
      this.databaseInfo = {
        instanceId: undefined,
        databaseName: undefined,
        id: val.id,
      };
      this.submitOverDumpOperationImport = submitOverDumpOperationImport;
      this.recoverShow = true;
    },
    // 搜索实例
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.getInstanceList(data);
    },
    // 选择实例
    handleInstanceListChange(instanceId) {
      if (!instanceId) {
        return;
      }
      let data = {
        instanceId,
      };
      this.$set(this.databaseInfo, "databaseName", undefined);
      this.getShowDatabases(data);
    },
    // 查询实例
    getInstanceList(data) {
      instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          if (res.data) {
            let list = res.data.records;
            this.instanceList = list;
          }
        }
      });
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      showDatabaseForSuper(data).then((res) => {
        if (res.result === 200) {
          this.databaseList = res.data.result;
        }
      });
    },
    updateImportDatabaseSourceSubmit() {
      let data = { ...this.databaseInfo };
      api.updateImportDatabaseSource(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("恢复成功");
          this.recoverShow = false;
          if (this.submitOverDumpOperationImport) {
            this.dumpOperationImport(this.databaseInfo);
          } else {
            this.getDataImportLogList();
          }
        }
      });
    },
    // 点击预览
    previewClick(val) {
      let arr = this.separatorList.filter((item) => {
        return item.value == val.separator;
      });
      if (arr.length > 0) {
        this.importDataSeparatorType = val.separator;
      } else {
        this.importDataSeparatorType = "";
      }
      this.importDataSeparator = val.separator;
      this.excludeFirstRow = val.excludeFirstRow;
      this.importAccountExtraInfoId = val.accountExtraInfoId;
      this.importDataId = val.id;
      this.getDataImportPreview();
    },
    // 关闭导入预览
    importPreviewClose() {
      this.importPreviewColumns = [];
      this.importPreviewList = [];
      this.importPreviewHeaderList = [];
      this.tableNameSelect = "";
    },
    // 导入文件上传变化
    importDataUploadChange(info) {
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 导入成功`);
        this.$confirm({
          title: "确认",
          content: "是否立即同步",
          onOk: () => {
            this.importDataShow = false;
            this.importOrExportCount = 1;
            this.updateImportDatabaseSource(
              { id: info.file.response.data.id },
              true
            );
          },
          onCancel() {},
        });
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 导入失败`);
      }
    },
    // 查询导出记录
    getDataExportLogList(index) {
      if (index) {
        this.exportPageNo = index;
      }
      let data = {
        pageNo: this.exportPageNo,
        pageSize: 10,
      };
      api.dataDumpLogList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            switch (item.state) {
              case "WAITING":
                item.stateLabel = "等待执行";
                item.tagColor = "orange";
                break;
              case "RUNNING":
                item.stateLabel = "运行中";
                item.tagColor = "orange";
                break;
              case "SUCCESS":
                item.stateLabel = "成功";
                item.tagColor = "green";
                break;
              case "FAILURE":
                item.stateLabel = "失败";
                item.tagColor = "red";
                break;
            }
          });
          this.exportDataList = list;
          this.exportDataPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.exportChangePage(current),
          };
        }
      });
    },
    // 导出记录翻页
    exportChangePage(index) {
      this.getDataExportLogList(index);
    },
    dumpOperationImport(record) {
      api.dumpOperationImport({ id: record.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("开始导入");
          this.getDataImportLogList();
        }
      });
    },
    // 查询导入记录
    getDataImportLogList(index) {
      if (index) {
        this.importPageNo = index;
      }
      let data = {
        pageNo: this.importPageNo,
        pageSize: 10,
      };
      api.dataImportLogList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            switch (item.status) {
              case "UPLOADING":
                item.statusLabel = "上传中";
                item.tagColor = "orange";
                break;
              case "UPLOADED":
                item.statusLabel = "上传成功";
                item.tagColor = "green";
                break;
              case "UPLOADFAILURE":
                item.statusLabel = "上传失败";
                item.tagColor = "red";
                break;
              case "IMPORTING":
                item.statusLabel = "导入中";
                item.tagColor = "orange";
                break;
              case "WAITING":
                item.statusLabel = "等待中";
                item.tagColor = "orange";
                break;
              case "FAILURE":
                item.statusLabel = "导入失败";
                item.tagColor = "red";
                break;
              case "SUCCESS":
                item.statusLabel = "导入成功";
                item.tagColor = "green";
                break;
            }
          });
          this.importDataList = list;
          this.importDataPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.importChangePage(current),
          };
        }
      });
    },
    // 导入记录翻页
    importChangePage(index) {
      this.getDataImportLogList(index);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.getDataImportPreview();
    },
    importDataSeparatorTypeChange() {
      this.importDataSeparator = this.importDataSeparatorType;
      this.getDataImportPreview();
    },
    // 查询导入预览
    getDataImportPreview() {
      let data = {
        separator: this.importDataSeparator,
        excludeFirstRow: this.excludeFirstRow,
        id: this.importDataId,
      };
      this.importPreviewColumns = [];
      this.importPreviewList = [];
      api.dataImportPreview(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.data;
          let columnInfoList = res.data.columnInfoList;
          if (list.length > 0) {
            list[0].forEach((menu, i) => {
              let k = i + 1;
              let obj = {
                title: "Do not import",
                ellipsis: true,
                dataIndex: "content" + k,
              };
              this.importPreviewColumns.push(obj);
            });
          }
          if (columnInfoList.length > 0) {
            columnInfoList.forEach((item, index) => {
              if (index < this.importPreviewColumns.length && item.column) {
                this.importPreviewColumns[index].title = item.column;
              }
            });
          }
          list.forEach((item, index) => {
            let obj = {};
            obj["key"] = index + 1;
            item.forEach((menu, i) => {
              let k = i + 1;
              obj["content" + k] = menu.content;
            });
            this.importPreviewList.push(obj);
          });
          if (!this.importPreviewShow) {
            this.importPreviewShow = true;
            this.tableNameSelect = res.data.tableName;
            this.tableNameSelectChange(this.tableNameSelect);
          }
        }
      });
    },
    // 导入预览table选择
    tableNameSelectChange(name) {
      this.importPreviewHeaderList = [];
      if (name) {
        let sql = "SELECT * FROM `" + name + "` LIMIT 0,10";
        let params = {
          databaseId: this.databaseId,
          databaseName: this.databaseName,
          accountExtraInfoId: this.accountExtraInfoId,
          sql: sql,
        };
        api.sqlExecute(params).then((res) => {
          if (res.result === 200) {
            this.importPreviewHeaderList = res.data.columns;
            this.importPreviewHeaderList.push({ columnName: "Do not import" });
            if (!this.importAccountExtraInfoId) {
              res.data.columns.forEach((column, index) => {
                if (this.importPreviewColumns[index]) {
                  this.$set(
                    this.importPreviewColumns[index],
                    "title",
                    column.columnName
                  );
                }
              });
            }
          }
        });
      }
    },
    // 导入预览表头修改
    headerChange(dataIndex, title) {
      for (let i in this.importPreviewColumns) {
        if (this.importPreviewColumns[i].dataIndex === dataIndex) {
          this.importPreviewColumns[i].title = title;
        }
      }
    },
    // 导入预览确定
    importPreview_submit() {
      let columnRelationList = [];
      this.importPreviewColumns.forEach((item) => {
        let obj = {
          column: "",
          ignored: false,
        };
        if (item.title == "Do not import") {
          obj.ignored = true;
        } else {
          obj.column = item.title;
        }
        columnRelationList.push(obj);
      });
      let data = {
        accountExtraInfoId: this.accountExtraInfoId,
        databaseName: this.databaseName,
        id: this.importDataId,
        separator: this.importDataSeparator,
        excludeFirstRow: this.excludeFirstRow,
        columnRelation: JSON.stringify(columnRelationList),
        tableName: this.tableNameSelect,
      };
      api.updateImport(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.importPreviewShow = false;
          this.getDataImportLogList();
        }
      });
    },
    //右键菜单栏操作
    showTableContent() {
      let isAdd = true; // 新增
      // 已创建的内容窗口不在重复创建
      this.sqlPanes.forEach((item) => {
        let strShear = item.key.substring(0, item.key.lastIndexOf("_"));
        let paneName = strShear.substring(strShear.indexOf("_") + 1);
        let databaseKey = strShear.substring(0, strShear.indexOf("_") + 1);
        if (databaseKey === "content_" && paneName === this.sqlTableStructure) {
          this.editableTabsValue = item.key;
          isAdd = false;
        }
      });
      if (isAdd) {
        this.add("content_");
      } else {
        this.sqlHandleClick(this.editableTabsValue);
      }
    },
    exportExcel(format, sql) {
      this.exportDataForm.format = format;
      if (sql) {
        this.exportDataForm.sql = sql;
      } else {
        this.exportDataForm.sql = this.querySql;
      }
      this.exportDataShow = true;
    },
    createTable() {
      this.createTableName = "";
      this.createTableShow = true;
      this.copyTableShow = false;
      this.closeDiv();
    },
    copyTable() {
      this.createTableName = this.nodeLabel + "_copy";
      this.createTableShow = true;
      this.copyTableShow = true;
      this.closeDiv();
    },
    createTableSubmit() {
      let data = {
        accountExtraInfoId: this.accountExtraInfoId,
        databaseName: this.databaseName,
      };
      if (this.copyTableShow) {
        data.oldTableName = this.nodeLabel;
        data.newTableName = this.createTableName;
        api.copyTable(data).then((res) => {
          if (res.result == 200) {
            this.createTableShow = false;
            this.copyTableShow = false;
            if (res.data.success) {
              this.$message.success("复制成功");
              let name = this.createTableName;
              this.nodeLabel = name;
              this.nodeLabelShow = "";
              this.sqlTableStructure = name;
              this.querySql = "SELECT * FROM `" + name + "` LIMIT 0,300";
              this.editTableStructure();
            } else {
              this.$message.error(res.data.errorMessage);
            }
          }
        });
      } else {
        data.tableName = this.createTableName;
        api.createTable(data).then((res) => {
          if (res.result == 200) {
            this.createTableShow = false;
            if (res.data.success) {
              this.$message.success("创建成功");
              let name = this.createTableName;
              this.nodeLabel = name;
              this.nodeLabelShow = "";
              this.sqlTableStructure = name;
              this.querySql = "SELECT * FROM `" + name + "` LIMIT 0,300";
              this.editTableStructure();
              this.inquireTable();
            } else {
              this.$message.error(res.data.errorMessage);
            }
          }
        });
      }
    },
    dropTable() {
      if (
        this.tableCtrlClickList.length > 0 ||
        this.tableShiftClickList.length > 0
      ) {
        this.tableCtrlClickList.forEach((tableName) => {
          this.tableList.forEach((item) => {
            if (item.tableName == tableName) {
              this.$set(item, "drop", true);
            }
          });
        });
        this.tableShiftClickList.forEach((tableName) => {
          this.tableList.forEach((item) => {
            if (item.tableName == tableName) {
              this.$set(item, "drop", true);
            }
          });
        });
      } else {
        this.tableList.forEach((item) => {
          if (item.tableName == this.nodeLabel) {
            this.$set(item, "drop", true);
          }
        });
      }
      this.closeDiv();
    },
    truncateTable() {
      if (
        this.tableCtrlClickList.length > 0 ||
        this.tableShiftClickList.length > 0
      ) {
        this.tableCtrlClickList.forEach((tableName) => {
          this.tableList.forEach((item) => {
            if (item.tableName == tableName) {
              this.$set(item, "truncate", true);
            }
          });
        });
        this.tableShiftClickList.forEach((tableName) => {
          this.tableList.forEach((item) => {
            if (item.tableName == tableName) {
              this.$set(item, "truncate", true);
            }
          });
        });
      } else {
        this.tableList.forEach((item) => {
          if (item.tableName == this.nodeLabel) {
            this.$set(item, "truncate", true);
          }
        });
      }
      this.closeDiv();
    },
    renameTable() {
      //重命名表
      this.nodeLabelShow = this.nodeLabel;
      this.$nextTick(() => {
        document.querySelector(".treeInput").focus();
      });
    },
    renameTableSubmit(item) {
      this.$set(item, "newTableName", this.nodeLabel);
      this.nodeLabelShow = "";
    },
    // 右键点击
    onRightClick(item, e) {
      this.nodeLabel = "";
      this.sqlTableStructure = "";
      if (item) {
        let name = item.tableName;
        this.nodeLabel = name;
        this.nodeLabelShow = "";
        this.sqlTableStructure = name;
        this.querySql = "SELECT * FROM `" + name + "` LIMIT 0,300";
      }
      this.showDiv1(e);
    },
    clickTable(item, index, event) {
      this.closeDiv();
      if (event.ctrlKey || event.metaKey) {
        // 如果同时按下了Ctrl键
        this.tableShiftClickList.splice(0);
        this.tableShiftClickEndIndex = -1;
        this.tableCtrlClickList.push(item.tableName);
      } else {
        let arr = [];
        Object.keys(this.tabCreated).forEach((tabItem) => {
          let itemArr = tabItem.split("_");
          arr.push({
            prefix: itemArr[0],
            tableName: itemArr.slice(1, -1).join("_"),
            suffix: itemArr[itemArr.length - 1],
          });
        });
        arr = arr.filter((newItem) => {
          return newItem.tableName == item.tableName;
        });
        if (event.shiftKey) {
          // 如果同时按下了Shift键
          this.tableCtrlClickList.splice(0);
          this.tableShiftClickList.splice(0);
          if (this.editableTabsValue.indexOf("query_") !== -1) {
            this.examineTable(item, index, arr);
          } else {
            this.tableShiftClickEndIndex = index;
            let stratIndex = 0,
              endIndex = 0;
            if (this.tableShiftClickStartIndex < this.tableShiftClickEndIndex) {
              stratIndex = this.tableShiftClickStartIndex;
              endIndex = this.tableShiftClickEndIndex;
            } else {
              stratIndex = this.tableShiftClickEndIndex;
              endIndex = this.tableShiftClickStartIndex;
            }
            this.tableList.forEach((table, index) => {
              if (index >= stratIndex && index <= endIndex) {
                this.tableShiftClickList.push(table.tableName);
              }
            });
          }
        } else {
          // 普通点击事件
          this.examineTable(item, index, arr);
        }
      }
    },
    examineTable(item, index, arr) {
      this.tableCtrlClickList.splice(0);
      this.tableShiftClickList.splice(0);
      this.tableShiftClickStartIndex = index;
      this.tableShiftClickEndIndex = -1;
      this.tableTitle = item.tableName;
      if (arr.length > 0) {
        if (this.editableTabsValue == arr[0]) {
          return;
        } else {
          this.sqlHandleClick(
            arr[0].prefix + "_" + arr[0].tableName + "_" + arr[0].suffix
          );
        }
      } else {
        if (this.editableTabsValue.indexOf("query_") == -1) {
          this.remove(this.editableTabsValue, true);
        }

        this.nodeLabel = item.tableName;
        this.sqlTableStructure = item.tableName;
        this.showTableContent();
      }
    },
    editTableStructure() {
      //编辑表结构
      let isAdd = true; // 新增
      // 已创建的表结构窗口不在重复创建
      this.sqlPanes.forEach((item) => {
        let strShear = item.key.substring(0, item.key.lastIndexOf("_"));
        let paneName = strShear.substring(strShear.indexOf("_") + 1);
        let databaseKey = strShear.substring(0, strShear.indexOf("_") + 1);
        if (
          databaseKey === "structure_" &&
          paneName === this.sqlTableStructure
        ) {
          this.editableTabsValue = item.key;
          isAdd = false;
        }
      });
      if (isAdd) {
        this.add("structure_");
      } else {
        this.sqlHandleClick(this.editableTabsValue);
      }
    },
    showDiv1(e) {
      let div1 = document.getElementById("div1");
      div1.style.left = e.clientX + 10 + "px";
      if (window.innerHeight - e.clientY < 300) {
        div1.style.top = window.innerHeight - 300 + "px";
      } else {
        div1.style.top = e.clientY + "px";
      }
      div1.style.position = "absolute";
      div1.style.display = "block";
    },
    subMenuShow1_4(e) {
      let div = document.getElementById("div1_4");
      div.style.left = 151 + "px";
      div.style.top = 156 + "px";
      div.style.position = "absolute";
      div.style.display = "block";
    },
    closeSubMenu() {
      let div1_4 = document.getElementById("div1_4");
      div1_4.style.display = "none";
    },
    closeDiv() {
      //关闭所有弹出层
      let div1 = document.getElementById("div1");
      let div1_4 = document.getElementById("div1_4");
      div1.style.display = "none";
      div1_4.style.display = "none";
      let select_div = document.getElementById("select_content_table");
      if (select_div) {
        document.body.removeChild(select_div);
      }
      this.tableCtrlClickList.splice(0);
      this.tableShiftClickList.splice(0);
      this.tableShiftClickEndIndex = -1;
    },
  },
  beforeDestroy() {
    if (Object.keys(this.fetchResultTimerMap).length > 0) {
      for (const key in this.fetchResultTimerMap) {
        const element = this.fetchResultTimerMap[key];
        clearTimeout(element);
      }
    }
    if (this.executedStatementsTimer) {
      clearInterval(this.executedStatementsTimer);
      this.executedStatementsTimer = null;
    }
    this.clearLoadingTimer();
  },
};
</script>
<style scoped lang="scss">
.sqlCommandSystem {
  width: 100%;
  height: 100%;
  position: relative;

  .load_animation {
    width: 100%;
    height: 100%;
    background: rgba(225, 225, 225, 0.3);
    z-index: 9999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .load_content {
      width: 350px;
      border-radius: 15px;
      overflow: hidden;
      background: #fff;
      padding: 10px 10px 0;

      .cancelButton {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }

  #div1,
  #div1_4 {
    display: none;
    width: 150px;
    border: 1px solid #e1e1e1;
    z-index: 1000;
    background: #fff;
    box-shadow: 0 0 6px rgb(192, 196, 204);
    border-radius: 2px;
    cursor: pointer;

    ul {
      margin: 0;
      li {
        padding: 5px 10px;
        font-size: 12px;
        &:hover {
          background: #c1dbf7;
        }
        &[disabled] {
          pointer-events: none !important;
          color: #00000040 !important;
        }
      }
    }
  }

  .button {
    font-size: 20px;
    width: 40px;
    height: 40px;
    color: #000000;
    &:hover {
      background-color: #e6f7ff;
      color: #000000;
    }
  }
  .badge {
    &::v-deep .ant-badge-dot {
      top: 5px;
      right: 10px;
    }
  }

  .header {
    height: 50px;
    line-height: 50px;
    color: #333;
    background: #fff;
    padding: 0 5px;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e1e1e1;

    .title {
      font-weight: bold;
      display: inline-block;
      font-size: 14px;
      width: 215px;
    }

    .headerTitle {
      height: 30px;
      width: calc(100% - 515px);
      margin: auto;
      border-radius: 5px;
      line-height: 30px;
      padding-left: 10px;
      font-size: 12px;
      color: #04000099;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid #f2f2f2;

      .setting {
        position: absolute;
        width: 200px;
        height: 50px;
        left: 0;
        top: 35px;
        background-color: #efeeef;
        display: none;
        z-index: 999;
        border: 1px solid #f2f2f2;
      }
      &:hover {
        .setting {
          display: block;
        }
      }
    }

    .avatar {
      margin: 0 5px 5px 0;
      vertical-align: middle;
    }
  }

  .main {
    height: calc(100% - 50px);

    ::v-deep .ant-layout-sider-children {
      height: 100%;
      background: #fff;
      flex: 0 0 auto;
      max-width: none;
      min-width: inherit;
      overflow-y: scroll;
      border-left: 1px solid #ededed;

      &::-webkit-scrollbar {
        display: none;
      }

      .aside_input {
        padding: 10px 0 10px 4px;
        width: 100%;
      }
    }

    .databaseMenu {
      width: 70px;

      .databaseTab {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #ededed;
        padding: 16px 0;
        cursor: pointer;

        &:hover {
          background: #ededed;
        }

        .icon {
          font-size: 16px;
          color: #db3636;
        }

        .databaseName {
          font-weight: 500;
          margin: 0 auto;
          font-size: 12px;
          word-wrap: break-word;
        }
      }

      .databaseTabActive {
        background: #ededed;
      }
    }

    .tableMenu {
      height: 100%;
      background: #ededed;

      .tableList {
        height: calc(100% - 44px);
        overflow: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #7c7c7c;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #7c7c7c;
        }

        .checkTableItem {
          background-color: #d1edfa;
        }

        .drop {
          background-color: #f9d8d5;
        }

        .truncate {
          background-color: #f8f7c3;
        }

        .rename {
          background-color: #ffa500;
        }

        .ctrlOrShiftTableItem {
          background-color: #2f52d0;
          color: #fff !important;
        }

        .tableItem {
          width: 100%;
          padding-left: 10px;
          font-size: 12px;
          display: inline-block;
          height: 24px;
          padding-right: 5px;
          color: #000000a6;
          line-height: 24px;
          cursor: pointer;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;

          &:hover {
            background-color: #e6f7ff;
            color: #000000a6 !important;
          }

          .tableName {
            font-weight: 500;
            white-space: nowrap;
            user-select: none;

            div {
              display: inline-block;
            }

            .icon {
              color: #1890ff;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .mainMiddle {
      height: 100%;
      overflow: hidden;

      .mainMiddleContent {
        height: calc(100% - 37px);
        background: #fff;
        position: relative;
        padding-left: 1px;

        #tz {
          width: 1px;
          height: 100%;
          border: 0px solid #ededed;
          position: absolute;
          left: 0px;
          top: 0px;
          background-color: #eee;
          cursor: w-resize;
        }

        .tab-class {
          height: 100%;

          .textareaClass {
            position: relative;
            width: 100%;
            height: 260px;

            .codesql {
              font-size: 11pt;
              font-family: Consolas, Menlo, Monaco, Lucida Console,
                Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
                Courier New, monospace, serif;
            }

            &::v-deep .CodeMirror {
              .CodeMirror-scroll {
                box-sizing: content-box !important;

                .CodeMirror-lines {
                  padding: 0px !important;
                  .CodeMirror-line {
                    font-size: 13px !important;
                    line-height: 150% !important;
                  }
                }

                .CodeMirror-gutters {
                  border-right: none;
                  background-color: #fff;
                }
              }
            }
          }

          &::v-deep .ant-tabs-bar {
            margin: 0;
            background: #f9f9f9;

            .ant-tabs-tab {
              border: 0px !important;
              background: #f9f9f9 !important;
              margin: 0px !important;
              border-radius: 0px !important;
              border-bottom: 1px solid #e8e8e8 !important;
              border-right: 1px solid #e8e8e8 !important;
            }
            .ant-tabs-tab-active {
              background: #ffffff !important;
              border-bottom: none !important;
            }
          }

          &::v-deep .ant-tabs-content {
            height: calc(100% - 40px);
            .ant-tabs-tabpane-active {
              height: 100%;
              position: relative;
            }
          }

          &::v-deep .ant-tabs-extra-content {
            span {
              display: block;
              height: 40px;
              margin-right: 8px;
            }
            .ant-tabs-new-tab {
              top: 5px;
              width: 30px;
              height: 30px;
              font-size: 20px;
              border: 1px solid #bfbfbf;
            }
          }

          .cx_button {
            display: flex;
            width: 100%;
            padding: 5px 10px;

            button {
              margin-right: 10px;
            }

            .limitSqlSelect {
              width: 110px;
              font-size: 12px !important;
              &::v-deep .ant-select-selection {
                border: none !important;
                &:focus {
                  border: none !important;
                  box-shadow: none !important;
                }
              }
            }
          }

          .tx_div {
            width: 100%;
            height: 1px;
            background-color: #eee;
            cursor: s-resize;
          }

          .resultTable {
            box-sizing: border-box;
            height: calc(100% - 295px);
          }

          .contentTable,
          .structureTable {
            height: 100%;
          }

          .structureTable {
            display: flex;
            flex-direction: column;
            overflow: auto;

            .structureTableTop {
              height: 480px;
            }

            .structureTableBottom {
              flex: 1;
              min-height: 0; /* 允许子元素撑开 */
              background: #f1f2f3;

              .bar {
                .title {
                  display: flex;
                  flex-direction: row;
                  padding-bottom: 5px;

                  div {
                    width: 50%;
                    padding-left: 10px;
                  }
                }
              }

              .indexTable {
                display: flex;
                flex-direction: row;
              }

              .indexTable {
                height: calc(100% - 36px);

                .indexTableLeft,
                .indexTableRight {
                  min-height: 100%;
                  width: 50%;
                  height: 315px;
                }
                .indexTableRight {
                  .ant-input {
                    height: 100%;
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        width: 100%;
        position: relative;

        .pagination_info {
          padding: 0px 10px;
          position: absolute;
          left: 45%;
          top: 0px;
          color: #8e8e8e;
        }

        .pagination_div {
          float: right;
        }
      }
    }
  }
}

.up_down_style_import {
  font-size: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: #5b5b5b;
}

.importPreviewHeader_class {
  li {
    &:hover {
      background: #c1dbf7;
    }
  }
}

.tagColorPopover {
  .ant-popover-inner {
    background-color: #efeeefc8 !important;
  }
  .ant-popover-arrow {
    border-color: #efeeefc8 !important;
  }
  .colorList,
  .tagList {
    display: flex;
    .label {
      margin-right: 10px;
    }
  }
  .colorList {
    .colorDiv {
      width: 30px;
      height: 30px;
      margin: 0 10px 10px 0;
      border-radius: 5px;
      cursor: pointer;
    }
    .white {
      background-color: #f8f8f8;
    }
    .blue {
      background-color: #bfcbf1;
    }
    .yellow {
      background-color: #f8f7c3;
    }
    .green {
      background-color: #ddebc6;
    }
    .red {
      background-color: #f9d8d5;
    }
  }
  .tagList {
    .ant-tag {
      cursor: pointer;
    }
  }
}

.input-key {
  &::v-deep .ant-modal-header {
    text-align: center;
  }
  &::v-deep .ant-modal-body {
    text-align: center;
  }

  .keyInput {
    width: 350px;
    text-align: center;
  }
}

.sqlDropdown {
  .ant-dropdown-menu-item {
    padding: 0 10px !important;
    .ant-btn-link {
      color: #000000a6 !important;
    }
  }
}
</style>
<style lang="scss">
.dataBaseTabTooltip {
  .ant-tooltip-inner {
    width: 320px;
  }
}
</style>
